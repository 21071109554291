import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/new-years-evil-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1980 slasher movie, New Year's Evil"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 31</h1>
                    <h2>New Year's Evil</h2>
                    <h3>January 8, 2024</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                    <h1>Transcript</h1>
                        <p><Link to="/episodes/new-years-evil">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:02.463)
                            You are listening to bring me the axe. I&#39;m Brian White, one half of this morbid equation and I&#39;m joined by my cohost and actual brother, Dave White. Hey Dave, how you doing over there?</p>
                            <p>Dave! (00:10.758)
                            No, hello. I&#39;m all right. It&#39;s I think it&#39;s officially winter. Did you get a lot of snow today?</p>
                            <p>Bryan! (00:19.283)
                            Oh yeah, it snowed like fucking crazy over here.</p>
                            <p>Dave! (00:21.494)
                            You know what I hate? I hate winter. I hate snow.</p>
                            <p>Bryan! (00:24.147)
                            No, no, I&#39;m into this for like today. Like this is my one day and then it&#39;s going to be a pain in the ass. Like every time I have to go out now, the car will slide a little bit and then I&#39;ll be done with winter. But right now I&#39;m okay with it.</p>
                            <p>Dave! (00:37.407)
                            I&#39;m not, if it was a person I&#39;d punch it right in the mouth. Hear that, hear that snow coming for you.</p>
                            <p>Bryan! (00:44.368)
                            Yeah, any face snow. Oh, so we practically grew up in neighborhood video stores and the steady diet of utter garbage that those shops provided us with continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies than Dave. Just before we get into it, here&#39;s a little housekeeping. If you want to keep up with us between the episodes, you can also find us on Instagram at bring me the ax pod and Dave&#39;s over there at that queer wolf.</p>
                            <p>We&#39;ve also got a sweet website now at bring me the axe.com. You can listen to all our past shows there and read the transcripts. And you can also contact us directly at bring me the axe pod at gmail.com. With any questions, comments or suggestions. Do let us know if there&#39;s a movie that you love like to hear us give it the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts. We&#39;re on YouTube now. Search us by name and subscribe to it. If you prefer to consume your podcast that way.</p>
                            <p>And you&#39;d be doing us a favor by leaving us a five star review on Apple podcasts and Spotify. So come on, come on and do it.</p>
                            <p>Dave! (01:43.906)
                            Yeah, it doesn&#39;t take anything. You just push a button. That&#39;s all you gotta do.</p>
                            <p>Bryan! (01:47.211)
                            You push a button, you push a button, and then it&#39;ll say like, how many stars? And you&#39;ll say five stars, because I like them just that much. They really do make it easy. I used to think it was a whole process, like you had to write a review and stuff, but like if you do write a review, that&#39;s fucking awesome, because it goes a little bit further, but you know, you don&#39;t have to, you just push the five star thing. And it helps us out a lot. And if you listen on YouTube, you do us a favor, give the episode a like, and leave a comment. We love hearing from you guys.</p>
                            <p>Dave! (02:14.747)
                            YouTube on YouTube.</p>
                            <p>Bryan! (02:16.787)
                            on the on the on the on the on the on the on the on the on the on the on the on the on the on the on the on the on the</p>
                            <p>Dave! (02:25.931)
                            Ugh.</p>
                            <p>Dave! (03:39.555)
                            Mm-mm. Nope.</p>
                            <p>Bryan! (03:40.741)
                            I don&#39;t like that.</p>
                            <p>Dave! (04:17.88)
                            Mm-hmm.</p>
                            <p>Dave! (04:21.858)
                            Damn it.</p>
                            <p>Bryan! (04:51.742)
                            Motherfucker. Yeah, I&#39;m back. I&#39;m back. God damn it. All right. All right. New Year&#39;s evil.</p>
                            <p>Dave! (04:52.099)
                            Oh, you&#39;re back.</p>
                            <p>Bryan! (05:03.55)
                            That&#39;s it. New Year&#39;s Evil.</p>
                            <p>Dave! (05:05.17)
                            down and down the down. You know what it reminds me of? You ever play that game twisted metal? It reminds me of the song from Twisted Metal, and I don&#39;t know if it actually sounds anything like it. It just reminds me of it.</p>
                            <p>Bryan! (05:11.759)
                            Oh yeah, yeah.</p>
                            <p>Bryan! (05:19.542)
                            I cannot remember the last time I played Twisted Metal. Probably in the 90s. But yeah.</p>
                            <p>Dave! (05:24.742)
                            I have a lot of questions about this movie. I have so many questions about this movie.</p>
                            <p>Bryan! (05:28.594)
                            I got a lot of questions too. I. Man. Yeah. Like it&#39;s a it&#39;s about as silly as they come and I there&#39;s a couple of points to it that I really actually kind of appreciate because I don&#39;t think I&#39;ve ever seen a slasher movie done quite this way. We&#39;ll we&#39;ll get there when we when we get there, but uh yeah, let&#39;s uh here&#39;s some facts. So uh this was made in 1980. So,</p>
                            <p>Dave! (05:32.755)
                            I laughed a lot, I know that.</p>
                            <p>Dave! (05:55.555)
                            Ish. That was made in 1980, I guess, yeah.</p>
                            <p>Bryan! (05:58.478)
                            Yeah, yeah. So I actually in the true canon style, I guarantee like a lot of movies that we do, they&#39;ll say like they were released in 1982. But it was actually made in 1981, you know, it&#39;s just the production process but canon moves like it&#39;s on fire and like, yeah, so like breaking and breaking to were both produced and released in 1984. Like months apart from each other entire productions.</p>
                            <p>Dave! (06:13.034)
                            Yeah, it was like three weeks later.</p>
                            <p>Dave! (06:27.162)
                            Oh, it was what&#39;s the one we watched there for 99 cent. It was Bronx Warriors. They recorded all three of those movies within six months.</p>
                            <p>Bryan! (06:33.366)
                            Brunk in six months. Yeah. It&#39;s both that&#39;s crazy. Cause yeah, like this one, one of the things I saw was, is this had a three week shooting schedule and your average Hollywood production rate rearing just from eight to 12 weeks. So holy shit, man. And that&#39;s really, that&#39;s kind of, that&#39;s three weeks, I guess is kind of standard for like low budget stuff, but still that&#39;s a fast production.</p>
                            <p>Dave! (06:44.909)
                            Yeah.</p>
                            <p>Dave! (07:00.05)
                            I think that&#39;s like TV movies are usually like two to three weeks for just the shooting schedule, not the whole turnaround, but.</p>
                            <p>Bryan! (07:06.354)
                            Yeah. So, some other movies released in that year. We&#39;ve got Dar yeah, we got Dario Argento&#39;s Inferno. Uh the ninth configuration came out that year as well which I&#39;m not sure if I&#39;d call that a horror movie but it&#39;s got horror bits and pieces. Um Motel Hell came out that year. I watched I watched that one the other night.</p>
                            <p>Dave! (07:10.122)
                            All of them. All of the slasher movies.</p>
                            <p>Dave! (07:20.782)
                            Meh. Mm-mm.</p>
                            <p>Dave! (07:30.66)
                            Hmm. I don&#39;t think I&#39;ve seen that in, I don&#39;t know, 15 years probably.</p>
                            <p>Bryan! (07:33.378)
                            I had I think the last time I saw it was on whatever. Joe Bob Briggs was doing for TNT like in a million years ago. Let&#39;s see. We also had maniac, which we cover way back in episode number four. So do check that one out. Also 1980, The Watcher in the Woods, which we covered. We covered not that long ago with Terry Gamble.</p>
                            <p>Dave! (07:46.567)
                            Mmm!</p>
                            <p>Dave! (07:54.937)
                            I&#39;m good.</p>
                            <p>Bryan! (07:59.71)
                            So go back, listen to those two. They&#39;re they&#39;re pretty. They&#39;re pretty entertaining. And I tell her really, really. The watcher in the woods. The ending the end result of listening to us talk about it is about the same as watching it. Yeah, but yeah, listen to the listen to the maniac episode for a really fucking crazy story about the time I met Tom Savini. Casted crew for this movie. The director is a character named Emmett Alston. Yes, so.</p>
                            <p>Dave! (08:03.626)
                            But don&#39;t watch Watcher in the Woods. You just listen to the thing. You&#39;ll get the gist of it.</p>
                            <p>Dave! (08:12.745)
                            Mm-hmm.</p>
                            <p>Dave! (08:25.075)
                            A lot of ninja movies under this guy&#39;s belt.</p>
                            <p>Bryan! (08:28.33)
                            he was a utility director for Canon and apart from this one, his resume is remarkably limited, but he did direct a bunch of those cheap action movies in the jungle during America&#39;s like really troubling love in period with the Marcos Philippines and also during Canon&#39;s Ninja Fever period and among them. Mid mid 80s, so 85. I think I think.</p>
                            <p>Dave! (08:49.278)
                            Well, I mean, what so what is that? That&#39;s like early 80s, right? So that&#39;s when we&#39;re still like that is when people are finally starting to confront the well, not the consequences, but like Vietnam, like the Vietnam War. It&#39;s like I think there&#39;s like four years out from the Morial Wall being built. And that was a huge fucking deal because people thought that was kind of a slight against veterans. And so all that shit is followed by these like kind of valorizing movies that are real gross and yet still somehow kind of fun to watch.</p>
                            <p>Rambo.</p>
                            <p>Bryan! (09:21.126)
                            Oh, yeah, and shitload of more shot. There&#39;s a there&#39;s a movie, I think the director&#39;s name is Mark Hartley. He did that. Not quite Hollywood documentary about Australia&#39;s exploitation industry. He followed that one up with a documentary called Machete Maidens Unleashed. And it&#39;s all about that, that period, those movies and the sort of the politics around that. And it&#39;s fucking horrifying.</p>
                            <p>because of what was actually happening in the Philippines at the time. It&#39;s nuts. And so yeah, this guy made a bunch of them over there, including Nine Deaths of the Ninja, which he did with Sho Kosugi. And it&#39;s a fucking crazy movie. Like I remember when I, Force of the, yep. Very ninja centric. Oh yeah, like that was a period where Canon couldn&#39;t make enough ninja movies. They just could not make enough ninja movies.</p>
                            <p>Dave! (09:59.382)
                            I mean, he also made Force of the Ninja and Little Ninjas. His very ninja-centric resume.</p>
                            <p>Bryan! (10:14.154)
                            Oh man, so yeah. In like 85, 86 ninjas were fucking everywhere.</p>
                            <p>Dave! (10:14.338)
                            Yeah, ninjas were really, really hot in the 80s. People loved ninjas.</p>
                            <p>Dave! (10:21.802)
                            And then you pair them with lovable turtles, and you get a phenomenon. Yeah. I love it.</p>
                            <p>Bryan! (10:25.01)
                            Yeah. And you get a little you get a little radioactive ooze on them and then you got a whole phenomenon. I love it. Can&#39;t complain. No complaints. So let&#39;s see cast. Key among this cast is Roz Kelly who played Pinky Tusk Adara on Happy Days. And if you go to her Wikipedia as I did and you look up her personal life section, it&#39;s just a list of all the times she&#39;s done something insane and or criminal.</p>
                            <p>Dave! (10:39.395)
                            Mmm. Yes, Pinky Tuscadero.</p>
                            <p>Dave! (10:54.164)
                            Yikes.</p>
                            <p>Bryan! (10:55.81)
                            Tough life there, Pinky Tuscanera.</p>
                            <p>Dave! (10:56.85)
                            Yeah, one gets the impression things did not really work out so great for Pinky Tuscadero, which is very sad.</p>
                            <p>Bryan! (11:02.146)
                            So here&#39;s the thing with, here&#39;s the thing. She, there&#39;s like, I remember in the nineties, there was a punk band called Tuscadero. Everybody talks about her. When they talk about her, they talk about happy days. She was on, she&#39;s on three episodes. They must&#39;ve been fucking awesome episodes.</p>
                            <p>Dave! (11:08.884)
                            Mm-hmm.</p>
                            <p>Dave! (11:13.33)
                            Oh, she&#39;s only on three episodes. Mm hmm. Her sister on the show was played by Susie Quatro, and Susie Quatro is real fucking cool.</p>
                            <p>Bryan! (11:21.254)
                            Right. Yeah, yeah, she was leather Tuscan arrow.</p>
                            <p>Dave! (11:25.702)
                            Now, the weird thing, though, about. So here&#39;s my question for you. We&#39;ve all seen Happy Days a million times. How old is Fonzie supposed to be? Because I was thinking about that today. Because I assumed he was kind of a high school student like them. I don&#39;t know if he like went to high school with them, but he&#39;s like a couple years older, maybe.</p>
                            <p>Bryan! (11:32.768)
                            Mm-hmm.</p>
                            <p>Bryan! (11:37.161)
                            I have no idea. I think.</p>
                            <p>Bryan! (11:45.178)
                            Yeah, so I think if anything, he&#39;s probably like 20. Maybe.</p>
                            <p>Dave! (11:48.734)
                            So this movie is like, I don&#39;t know, a few years after Happy Days, you know, after she&#39;s on Happy Days, she is like a 55 year old divorcee in this movie. How does she go from playing a 20 year old? And I think there is some weird age discrepancies stuff like with her kid and all that. But like, she does not look like she&#39;s in her 20s. And that&#39;s like a that&#39;s the early 80s. Kind of like, you know, you&#39;re like an early 80s 30, which means that you look like you&#39;re 52.</p>
                            <p>Bryan! (11:55.027)
                            Yeah.</p>
                            <p>Bryan! (11:58.194)
                            She... I know! Uh-huh.</p>
                            <p>Bryan! (12:10.716)
                            Oh yeah.</p>
                            <p>Bryan! (12:15.226)
                            It&#39;s yeah, it&#39;s the consequence of cocaine and two packs a day smoking habit like just ages the shit out of you. But that&#39;s that thing about the age discrepancy is something that really fucking threw me because there is a moment in the movie where there&#39;s just the big reveal and I&#39;m like, that guy is her husband. He&#39;s like the same age as her son. So yes. So speaking of her husband, we got kip</p>
                            <p>Dave! (12:21.948)
                            Mm-hmm.</p>
                            <p>Bryan! (12:42.706)
                            who was a TV guy who did some disaster movies for Universal. And he seems to be having a really, really good time in the role of the killer in this movie.</p>
                            <p>Dave! (12:50.294)
                            Mm hmm. I only remember him from Magnum Force because he&#39;s one of the killers in that.</p>
                            <p>Bryan! (12:54.346)
                            Yeah. Cuz that&#39;s um he doesn&#39;t have a lot of like central key roles and that&#39;s one of them. Like he&#39;s even in like I think he&#39;s an yeah. That&#39;s I believe that&#39;s the sequel to Dirty Harry like the first one, right? Yeah. So uh yeah. No but he yeah he he&#39;s always like bit parts. He did a ton of TV. I believe he&#39;s still working.</p>
                            <p>Dave! (13:03.178)
                            And that&#39;s an early one, because that&#39;s what, 74?</p>
                            <p>Dave! (13:09.388)
                            Mm-hmm.</p>
                            <p>Dave! (13:19.114)
                            Yeah, everybody in this movie is like, you just look at their IMDB. It&#39;s like television. Every fucking show from the 70s and 80s, they&#39;re in it.</p>
                            <p>Bryan! (13:26.138)
                            Yep, yep. One episode, two episodes, three episodes. It&#39;s it&#39;s pretty good. I what is it? The woman who plays the nurse, Taffy O&#39;Connell, I believe is her name. She she was on the show that I did not even know existed called Enus, which was the an attempted spin off of the Dukes of Hazard. I believe it lasted like I believe it lasted about three episodes and she&#39;s in all of them.</p>
                            <p>Dave! (13:38.176)
                            Yep.</p>
                            <p>Dave! (13:48.029)
                            Ugh.</p>
                            <p>Dave! (13:52.034)
                            Good God.</p>
                            <p>Bryan! (13:53.394)
                            Yep. So rounding out the cast here is Grant Kramer, who is better known for his lead role in Killer Clowns from Outer Space. He also has a small part in John Russo&#39;s weird Night of the Living Dead special edition, which I&#39;d completely forgotten about until I looked him up and I was like, oh my god, that&#39;s right. That thing exists. Do you remember this at all?</p>
                            <p>Dave! (13:57.934)
                            Mm-hmm. That&#39;s Mike.</p>
                            <p>Dave! (14:13.234)
                            Mm, vaguely, because I was reading about it like not too long ago, because there&#39;s a lot of weird shit that came around came out around because that came out at like an anniversary, right?</p>
                            <p>Bryan! (14:17.866)
                            Yeah.</p>
                            <p>Bryan! (14:22.566)
                            1999 is when it came out and it came out when like everybody was all of a sudden doing special editions because of what Lucas did for Star Wars so they did that with Night of the Living Dead and they did this whole they inserted this entire new scene that they shot that&#39;s I believe it&#39;s the funeral for Bill Hinsman&#39;s zombie at the very beginning of the movie and it</p>
                            <p>Dave! (14:47.51)
                            Why would you shoot an insert scene 45 years later?</p>
                            <p>Bryan! (14:51.09)
                            No fucking idea. No idea. Also, Hinsman somehow looks exactly the same in that movie as he did in fucking 1968. So.</p>
                            <p>Dave! (15:00.238)
                            Because I think he looks they make him look old in that because he&#39;s in some other shit that&#39;s like a couple of years later or like 10 or so years later. He looks exactly the same. I think they were just like, let&#39;s just keep making him look like that. And it just so happened that he started out looking old. So by the time he&#39;s 70, he just looks like that guy still.</p>
                            <p>Bryan! (15:03.562)
                            Yeah.</p>
                            <p>Yeah.</p>
                            <p>Bryan! (15:18.155)
                            Yeah. Yeah, man. The Living Dead got some real abuse around that period, because I believe they also made a I think it&#39;s not a sequel. I believe it&#39;s a remake with Sid Haig that&#39;s in 3D. I&#39;ve never seen. Yeah, no, thank you. Don&#39;t need it. So here are some taglines for this movie. Don&#39;t dare make any New Year&#39;s resolutions unless you plan to live. I plan to live. I.</p>
                            <p>Dave! (15:27.966)
                            Yes, it&#39;s fucking terrible.</p>
                            <p>Dave! (15:40.322)
                            Hmm</p>
                            <p>Dave! (15:45.166)
                            Eh... It&#39;s grammatically clunky.</p>
                            <p>Bryan! (15:47.31)
                            Yeah, it is. Also, a celebration of the macabre. Yeah, that&#39;s those are both on the same poster like the original theatrical poster. And then this one is. I know, I know why the celebration of macabre is at the bottom, it&#39;s very small. Then this one is the one that appeared on the video. This is this New Year&#39;s, you&#39;re invited to a killer party. I like that one, I like that one, it&#39;s pretty good.</p>
                            <p>Dave! (15:51.414)
                            That one&#39;s terrible.</p>
                            <p>Dave! (15:57.546)
                            We can&#39;t have two taglines.</p>
                            <p>Dave! (16:11.326)
                            No, I can get behind that one. I like that one.</p>
                            <p>Bryan! (16:14.994)
                            So like I said, uh three week shooting schedule. We this one was actually released in time for New Year&#39;s Eve in 1980. Oh, I&#39;m sure.</p>
                            <p>Dave! (16:22.73)
                            That was on purpose. So this was because this comes out like this is peak a golden age slasher like we like it&#39;s over that they&#39;ve reached the point of over saturation. And there are basically two movies that were kind of scheduled to come out. I think it was a little bit before that. It was this and slumber party massacre.</p>
                            <p>And Roger Corman had like the foresight to be like, No, fuck that. I&#39;m not putting out a movie when like everybody else is putting out movies. No one&#39;s going to go see this. So he held it. And they I think this one, they opted to be like, All right, let&#39;s push it as far back as we can go. And hopefully we can like, you know, pick up that period where like nothing comes out and maybe we&#39;ll get some audience that way. Now, it didn&#39;t really work because this movie was a, you know, a giant failure. But it&#39;s it speaks to.</p>
                            <p>Bryan! (16:56.607)
                            Yeah.</p>
                            <p>Bryan! (17:09.141)
                            Yeah.</p>
                            <p>Dave! (17:19.175)
                            just how oversaturated everything was. Like that is a very short window of time. That&#39;s what three years basically. And and there&#39;s like.</p>
                            <p>Bryan! (17:22.357)
                            Yeah.</p>
                            <p>Bryan! (17:26.386)
                            Yeah. Yes. So it&#39;s eighty to eighty to eighty two. So, yeah, three years.</p>
                            <p>Dave! (17:32.222)
                            Yeah. And that&#39;s like fucking insane to be like there were just too many of them. And everybody knew it, too. But they were like, well, what the fuck are we going to do? But I think this movie, I think, is a very good example of what happens when you don&#39;t follow the template for those movies, because, you know, most of the slasher movies, they&#39;re like, yeah, inciting incident. Period of time elapses. People start dying because of inciting incident. That&#39;s the that&#39;s the formula. This one, they were just like, yeah.</p>
                            <p>Bryan! (17:38.242)
                            Oh yeah. Not make money?</p>
                            <p>Bryan! (17:47.724)
                            Mm-hmm.</p>
                            <p>Bryan! (17:58.484)
                            Yeah.</p>
                            <p>Dave! (18:01.546)
                            You know what? We don&#39;t need that. But we have our own ideas, and I will say your own ideas do not make any sense.</p>
                            <p>Bryan! (18:03.031)
                            Right, so...</p>
                            <p>Bryan! (18:08.502)
                            Canon does okay. So Canon is not really known for horror movies, right? So they&#39;ve got all the Chuck Norris ones They&#39;ve got all the Charles Bronson. They were really like an action movie house and they</p>
                            <p>Dave! (18:19.338)
                            You should also point out that this is like second wave canon because canon was not canon has not always been canon.</p>
                            <p>Bryan! (18:24.186)
                            right? No, no, no. This is Golan Globus, Canon. So this is real, real early in that in that sort of phase of them. They I believe they&#39;re big, their first big movie was the Apple, which is a musical that</p>
                            <p>Dave! (18:36.234)
                            I think that predates when he bought when they bought Canon, I think because Menachem Golem directed that. And I think because of that, because that had some weird success, that is a very strange movie. And.</p>
                            <p>Bryan! (18:43.827)
                            Yes.</p>
                            <p>Bryan! (18:48.594)
                            Yes, it is. We&#39;ll eventually we&#39;ll get around to that one on 99 set.</p>
                            <p>Dave! (18:53.01)
                            And so he I think based on that, they were like, you know, we can because they basically they use Roger Corman&#39;s, you know, pre sell international rights strategy to just crank shit out. It doesn&#39;t matter how cheap and terrible it is because you&#39;ve already made your money back doesn&#39;t fucking matter what anybody thinks of it. And so I think it&#39;s right after the apple that they take over and they turn it into whatever it becomes throughout the 80s. And I think the first one was death with Death Wish 2.</p>
                            <p>Bryan! (19:02.631)
                            Yes, yeah.</p>
                            <p>Dave! (19:22.198)
                            I think that was like their first big success.</p>
                            <p>Bryan! (19:22.322)
                            You&#39;re right. Yeah. So yeah, we will. We will probably get into Canon group proper on 99 cent rental. There&#39;s again, I mentioned Mark Harley again, he has a documentary called electric boogaloo. The I believe it&#39;s called like the wild untold story of Kev Cannon films. And it&#39;s not a terribly flattering portrait of it. Steve rails back isn&#39;t it fucking hates everything he did with them. But</p>
                            <p>Dave! (19:48.51)
                            Oh, yeah, no, everybody at best. And I&#39;ve seen that documentary. I can&#39;t really remember it very well. But at best, people are like, well, they were very efficient businessmen. And it&#39;s like, that&#39;s the nicest thing you can say about them. Most people don&#39;t go so far as rails back to like really rag on them. But most people are just like they were very stern men.</p>
                            <p>Bryan! (19:58.215)
                            Yeah.</p>
                            <p>Yeah, because.</p>
                            <p>Bryan! (20:11.864)
                            Yeah. They did not participate in the Electric Boogaloo documentary. They instead opted to produce their own, which is a much more like fawning nostalgia love fest. I&#39;ve not seen it. I&#39;m not terribly interested in it.</p>
                            <p>Dave! (20:25.306)
                            I have seen pieces of it. I&#39;ve never watched the whole thing. It&#39;s a little bit hard to take. I mean, it&#39;s, you know, ego maniacal men basically like championing their shitty work. But it&#39;s so bizarre to me that anybody could watch these movies and be like, this is art. Like this shit is just the pinnacle. It&#39;s like they were putting out the trashiest movies. Now, a lot of them are really fun, but.</p>
                            <p>Bryan! (20:41.75)
                            I&#39;m sorry.</p>
                            <p>Bryan! (20:48.726)
                            Oh yeah, they&#39;re, they&#39;re ex, they&#39;re fun. I love so many of them, but I also am very aware that they are sexist, extremely homophobic, extremely racist.</p>
                            <p>Dave! (21:02.494)
                            Oh, yeah. And like just misogyny through and through. I mean, that&#39;s just kind of the 80s in general, but.</p>
                            <p>Bryan! (21:06.246)
                            Yeah. Right, right. But these guys, they really, they were really kind of in the zone when they were killing women in their movies. But yeah, to the point of what you said about this being kind of like not your average slasher movie and the way that they kind of they seem to sort of miss the point in a weird way and they land somewhere that&#39;s truly canon in that this is</p>
                            <p>Dave! (21:14.221)
                            Mm-hmm.</p>
                            <p>Dave! (21:31.614)
                            Yeah, because it has a very international feeling to it. There are moments where it feels very like jello. I mean, even that fucking mask, it&#39;s like shades of deep red. Like it&#39;s.</p>
                            <p>Bryan! (21:39.111)
                            Yes.</p>
                            <p>Bryan! (21:42.93)
                            Yes, and the fact that one of the primary weapons is a switchblade. You know, they got to give them a stiletto.</p>
                            <p>Dave! (21:46.55)
                            Yep, I mean the black gloves, the switchblade, the fucking voice on the phone.</p>
                            <p>Bryan! (21:50.614)
                            Yeah. Uh but also they so the the following year so they I guess they just couldn&#39;t figure out the slasher thing because this somehow they managed to not completely <em>**</em> and make bank on this movie. The following</p>
                            <p>Dave! (22:02.51)
                            That&#39;s what I&#39;m saying, because they didn&#39;t they didn&#39;t bother with the fucking formula, because if you go off, unless you are a very good director with a very clear vision, John Carpenter, you do not fucking deviate from the script. And the script is inciting incident time lapse. Start killing people connected with whatever incident was.</p>
                            <p>Bryan! (22:06.42)
                            Yeah.</p>
                            <p>Bryan! (22:14.421)
                            Yeah.</p>
                            <p>Bryan! (22:21.81)
                            Right, because the following year, they&#39;re going to release another slasher movie that we&#39;re doing next month. That is, is even weirder. It&#39;s equally as just divorced from reality and like psychedelic and strange. But where this one, I find this movie to be entertaining and kind of and really silly. That one, I, it&#39;s called X-ray, by the way. And that one.</p>
                            <p>Dave! (22:27.911)
                            Oh boy. It is even weirder. It is one of the weirdest movies I&#39;ve ever seen.</p>
                            <p>Dave! (22:47.762)
                            It is on Tubi. Please everybody go watch it. It is it is a mind bender of a film.</p>
                            <p>Bryan! (22:54.166)
                            pro tip X dash Ray when you search for it on Tubi because otherwise it won&#39;t show up. Yes. So so yeah, you know what? I don&#39;t want to blow it because we&#39;ll talk about it when we get there, but we both watched it twice within 24 hours. It went.</p>
                            <p>Dave! (22:59.43)
                            Or you could buy it. I personally own a copy of it.</p>
                            <p>Dave! (23:09.406)
                            Yeah, because I think I started it and within 20 minutes texted you and said you have to watch this movie. And the next day I was hanging out with a friend of mine. I was like, we have to watch this movie. It is bonkers.</p>
                            <p>Bryan! (23:21.786)
                            Yeah, because I was I was watching I was watching Death Stalker that night and I texted I was like this movie is wicked short I need something else. It was like this movie is like already over I need something else to watch and you were like listen I&#39;ve got this one on right now that you will not believe. So yeah.</p>
                            <p>Dave! (23:27.266)
                            God, we have terrible taste.</p>
                            <p>Dave! (23:38.034)
                            It is, ugh. Because it doesn&#39;t make any sense. This one is confusing. X-ray doesn&#39;t make any sense.</p>
                            <p>Bryan! (23:44.21)
                            No, so Canon eventually does get in on horror movies a little way a little ways deeper into the 80s, but they do it right. Sort of, but they do it.</p>
                            <p>Dave! (23:52.447)
                            They kind of go with the teen movies. Like, didn&#39;t they do The Gate, isn&#39;t that them?</p>
                            <p>Bryan! (23:55.726)
                            right? No, no. What they did was they got they hired people who knew how to make horror movies. They hired Toby Hooper and yeah and so you get you get Innovators for Mars and Texas Chainsaw Massacre 2 you get. So yeah so they eventually do kind of figure it out but they figure it out by just hiring the right people. Um and also that point when they made those movies they were flying.</p>
                            <p>Dave! (24:03.454)
                            All right, Life Force was and Evaders from Mars, too, I think is theirs.</p>
                            <p>Dave! (24:09.957)
                            Mm-hmm.</p>
                            <p>Bryan! (24:20.822)
                            crazy high and were able to just do whatever the fuck they wanted. Like that was around the time when they put stuff into production like Invasion USA and the Delta Force, which are ridiculous fucking movies, but they are way better than they have any right to be. It&#39;s nuts. So uh yeah.</p>
                            <p>Dave! (24:39.146)
                            We should probably also mention that this movie was written by a man who, when he wrote this, was 65 years old. And and you will feel that through and through.</p>
                            <p>Bryan! (24:46.13)
                            Yes, you definitely get a you definitely get a certain How do you do fellow kids while also getting while also getting a certain get off my lawn kind of. So also, this was not the only New Year&#39;s Eve movie made in 1980. The other was also was terror train. Terror train came up.</p>
                            <p>Dave! (24:53.911)
                            Oh man.</p>
                            <p>Dave! (24:59.457)
                            Mm-hmm.</p>
                            <p>Dave! (25:06.998)
                            Yeah, Terra Train&#39;s the same year. That movie, I would say, is quite a bit better than this one. And that&#39;s saying a lot because that movie&#39;s not very good. This one&#39;s a lot more fun.</p>
                            <p>Bryan! (25:11.727)
                            Yes.</p>
                            <p>Bryan! (25:15.006)
                            Hahaha!</p>
                            <p>This one&#39;s a lot more fun than that one, but this, but this, but that one is an objectively, a much better movie. So, uh, shall we get into it?</p>
                            <p>Dave! (25:26.31)
                            I&#39;m going to I want to just let you this is going to come as no surprise to you. This movie was not well received by the critics out in the world. Let&#39;s see. I&#39;m going to hit you with some quotes here. This is the L.A. Times. Even though New Year&#39;s Evil was released very early this month, the film will surely be one of the top contenders for one of the worst films of 1981. Yeah, uh-huh. Let&#39;s see here. Well, it&#39;s my boy, Roger Ebert.</p>
                            <p>Bryan! (25:32.548)
                            Oh good, oh good.</p>
                            <p>Bryan! (25:49.145)
                            Uh...</p>
                            <p>Dave! (25:53.594)
                            New Year&#39;s Evil is not a good movie or even a very good thriller. It&#39;s just barely competent. Well, it&#39;s pretty good. And Chicago Tribune, this one is my favorite. This one is particularly rough with all manner of mutilations. No stars. Like it actually says no stars.</p>
                            <p>Bryan! (26:00.929)
                            Okay, that&#39;s not as bad as I was expecting.</p>
                            <p>Bryan! (26:08.79)
                            ..</p>
                            <p>Bryan! (26:14.707)
                            Oh God, that&#39;s a little ridiculous. Oh boy.</p>
                            <p>Dave! (26:17.522)
                            I feel like a lot of the times, and you know, we&#39;ll keep going with this theme, but like, I feel like they just saw horror movie and were like, well, this is going to be garbage.</p>
                            <p>Bryan! (26:26.382)
                            I was going to say that I guarantee that the paper is like, okay, you&#39;re going to go and see this movie and this movie this week and you&#39;re going to write it up and they were like one of them is a fucking horror movie. Come on. And so like they sat through it. They probably took it in and just immediately forgot everything and read and just wrote whatever kind of skating bullshit they could because I mean it is not a good movie in the conventional sense, but it has its charms, you know.</p>
                            <p>Dave! (26:52.83)
                            I mean, but the stigma around horror is so, you know, especially at this time. And I get it. Like these movies, if you we talked about this with Halloween, we talked about this with Black Christmas. If you are of a certain age or a certain generation and you&#39;re accustomed to horror being a certain kind of thing, you know, it&#39;s monsters or it&#39;s aliens or giant bugs or whatever the fuck it is. If anything, prior to 1980, essentially.</p>
                            <p>When you get to this, I can see why you think this was kind of tasteless, because a lot of it is very tasteless and a lot of it is very crass.</p>
                            <p>Bryan! (27:23.078)
                            Oh yeah, this one is in fact tasteless.</p>
                            <p>Dave! (27:26.354)
                            Yeah, but to not be able to recognize that it&#39;s the same as giant bugs or monsters from outer space, it&#39;s the same shit. Like it&#39;s the same schlocky bullshit. It&#39;s just for a different generation. Like I, you know, I watch a lot because people are like, you don&#39;t watch enough modern movies. Well, I actually do watch a lot of them. I just don&#39;t like them. But when I watch them, I very easily understand this is not made for me.</p>
                            <p>Bryan! (27:38.568)
                            Yeah.</p>
                            <p>Bryan! (27:46.899)
                            Yeah.</p>
                            <p>Dave! (27:53.078)
                            You know, Five Nights at Freddy was not a movie that was made for a middle-aged man. And so I watch it with that in mind so I can come away with it being like, well, it was all right. It was kind of fun. It was very bad. And it was clearly not for me, the audience. But it&#39;s so weird that they would be like these garbage movies, blah, blah. It&#39;s like you didn&#39;t even did you walk out halfway through? Like you can&#39;t even recognize that, like it&#39;s the same thing. It&#39;s just about having fun. It&#39;s just a different.</p>
                            <p>Bryan! (27:57.679)
                            Right, yeah.</p>
                            <p>Bryan! (28:16.103)
                            Yeah.</p>
                            <p>Dave! (28:22.358)
                            way of presenting fun. And granted, some of the shit that ends up in these movies is kind of gross and awful. But I still think to constantly be like, well, here&#39;s another shitty horror movie. Like, God damn it, it&#39;s your fucking job, grow up.</p>
                            <p>Bryan! (28:37.335)
                            I don&#39;t know, way to be a reviewer, an objective film critique.</p>
                            <p>Dave! (28:42.25)
                            And that is my rant against 1980s film critics.</p>
                            <p>Bryan! (28:47.43)
                            So yeah. So the movie opens on the luxurious Holiday Inn somewhere among the Los Angeles skyline. Now, it is, yeah, when they, when they come back to it a little bit later on, I was like, wait a second, are they shooting this TV show at the Holiday Inn? Yeah. So Ernie</p>
                            <p>Dave! (28:54.861)
                            Mm, the rough hotel.</p>
                            <p>Dave! (29:04.297)
                            Pretty much.</p>
                            <p>Bryan! (29:07.838)
                            who is the anxious manager to Diane is giving Diane a hard time about being late for as we soon learn the new wave music television broadcast of the year. Drop a load. My note says drop a load and relax. That&#39;s what I heard her say.</p>
                            <p>Dave! (29:17.642)
                            And then she says, drop a lude and relax Ernie. Which I love that line. Drop a lude and relax. I&#39;m gonna say that to people now.</p>
                            <p>Dave! (29:30.564)
                            Well, I think either one, either one fits, I guess. Though I would not recommend saying that, you know, I&#39;m gonna recommend not saying either one to people because they&#39;ll either be disgusted or confused.</p>
                            <p>Bryan! (29:44.138)
                            did Quaaludes even do? Yeah. Jesus Christ. Yeah. All I know is they stopped making them and they just were like everybody&#39;s favorite drug until yeah until the world supply like literally ran out and like then you don&#39;t hear about it. I think the last time you heard about it was David Bowie was singing about him.</p>
                            <p>Dave! (29:46.261)
                            They are a sedative. We&#39;re a sedative, I guess.</p>
                            <p>Dave! (29:54.018)
                            Yeah, because I think we&#39;re like insanely addictive.</p>
                            <p>Bryan! (30:05.918)
                            Yeah, so there&#39;s a woman also like nervously applying the heaviest coat of makeup I&#39;ve ever seen outside of a drag show. Diane, soon to be known as Blaze, she looks like D. Snyder from Twisted Sister.</p>
                            <p>Dave! (30:21.646)
                            She really does, and it does not do her any favors because look, I do not like to comment on women&#39;s appearances, but they make her look so old in this.</p>
                            <p>Bryan! (30:33.458)
                            It&#39;s weird. It is definitely weird. The costuming is also not terribly, uh, complimentary.</p>
                            <p>Dave! (30:38.398)
                            Like she looks like someone&#39;s mom dressed up as a punk rocker for Halloween.</p>
                            <p>Bryan! (30:43.655)
                            Yeah, yeah.</p>
                            <p>Dave! (30:45.462)
                            But it&#39;s so it is just where&#39;s what I have a great note that I said, Oh, all the punks in this, they all look like they&#39;re damned by way of JCPenney.</p>
                            <p>Bryan! (30:57.714)
                            Yeah, because there&#39;s that one guy who looks like Dave Vainion, but like, but like he bought a Dave Vainion costume at Spirit Halloween.</p>
                            <p>Dave! (31:01.035)
                            Yeah.</p>
                            <p>Dave! (31:05.334)
                            And then like they&#39;ve got Blaze like with this ridiculous hair that is a lot more like motley crew than anything else. And but she looks like Joan Collins in like S&amp;M getup. Like it&#39;s just this really bizarre look. Like why are you making her look like that? She does not look young or hip and she doesn&#39;t look into any of this. Like it&#39;s just such a, the punk rock angle of this is so strange.</p>
                            <p>Bryan! (31:18.418)
                            You...</p>
                            <p>Bryan! (31:31.382)
                            it&#39;s it&#39;s suspect. We&#39;re going to get there because it plays a it looms largely over this and for a movie shot in in Hollywood at the <strong><strong> peak of like Hollywood of the Hollywood punk experience like I know like they could they could have got they could have got TSOL. They could have got DI. They could have got </strong></strong> in the germs but like uh huh.</p>
                            <p>Dave! (31:45.827)
                            Throw Darby Crash in there, give him a cameo, like anything, anything for authenticity because they missed the mark so widely.</p>
                            <p>Dave! (31:57.45)
                            The Vandals will be in anything.</p>
                            <p>Bryan! (32:00.11)
                            So, uh yeah. So now we see that Diane calls Yvonne who does God knows what for her and wants to know if she&#39;s heard from Richard who is probably yes yeah Richard who is probably drunk and won&#39;t be making it to the show tonight. We gotta remember Richard. So while Yvonne is getting ready for the big show she&#39;s distracted not once but twice by the dripping of the bathtub in the hotel room and so</p>
                            <p>Dave! (32:09.782)
                            You know what she does for her? She is the stereotype.</p>
                            <p>Dave! (32:26.89)
                            And she seems real put out by like having to turn the faucet. Like the second time around, she&#39;s just like, Oh, my God. It&#39;s like, Jesus, lady.</p>
                            <p>Bryan! (32:30.934)
                            Yeah, yeah, she does. It&#39;s it&#39;s listen, listen, Yvonne. It&#39;s right <em>**</em> next to you. Just reach in there and turn it off. So, yeah, while she&#39;s distracted,</p>
                            <p>Dave! (32:41.05)
                            But she doesn&#39;t. So for the first time, she does it. She reaches in, but she puts kind of her whole body in like a real lean in. So she is in kind of in the shower. I think that is significant for what I&#39;m going to say in a minute.</p>
                            <p>Bryan! (32:46.478)
                            Mm-hmm. Yeah.</p>
                            <p>Bryan! (32:54.13)
                            Yeah, because somebody someone is like lets themselves into her room and then attacks her with a stiletto.</p>
                            <p>Dave! (33:00.826)
                            In the shower, she never leaves the bathroom. How does this killer get into the room, into the bathroom where she is, into the shower where she just was leaning into without her seeing any of it?</p>
                            <p>Bryan! (33:15.222)
                            Uh huh. That oh my god. That&#39;s right. No, no, no. She does leave the bathroom very briefly. But still, like the logic of the scene, like kind of understanding the layout of the room. Like this person had to be the sneakiest killer in the in the entire world.</p>
                            <p>Dave! (33:30.55)
                            And this is like every hotel you&#39;ve, if you&#39;re not a wealthy person, this is every hotel you&#39;ve ever been to. This is the Radisson at the airport. They are not shooting for fancy here at all.</p>
                            <p>Bryan! (33:36.368)
                            Yeah</p>
                            <p>Bryan! (33:40.402)
                            No, it&#39;s like I said, it is the luxurious Holiday Inn, you know, glitzy, you know, Hollywood center of like the American pop culture fantasy. And like, there&#39;s like beautiful hotels all up and down that place. Now we get the Holiday Inn.</p>
                            <p>Dave! (33:58.378)
                            And you could have just faked it. You didn&#39;t have to actually book the hotel.</p>
                            <p>Bryan! (34:01.474)
                            I know, no, you see the actual fucking name on the building. It&#39;s like, ah, man, is this product placement? Did they pay for this? Who knows? But yeah, also she&#39;s got beads in her hair, like Olivia from Sesame Street. Every time she moves, it&#39;s a very noisy thing.</p>
                            <p>Dave! (34:12.702)
                            And I mean, lots of like.</p>
                            <p>It is like when Dave Chappelle did his Rick James impression. It is like that kind of beads where it is like just over the top. Like, I feel like whoever was doing hair in this movie maybe was a little bit racist.</p>
                            <p>Bryan! (34:22.707)
                            Ha ha ha!</p>
                            <p>Bryan! (34:30.858)
                            Yeah, it was the style at the time. So we now we cut to the theme song as the credits roll and a classic car with the rag top down drag drives down Hollywood Boulevard overflowing with heavily made up punks.</p>
                            <p>Dave! (34:34.14)
                            Mm-hmm.</p>
                            <p>Dave! (34:46.922)
                            Yeah, these are like these are the punks from Return of the Living Dead, basically, just in a slightly nicer car.</p>
                            <p>Bryan! (34:50.702)
                            Yes, it makes me. So this scene actually makes me wonder if this was a thing that used to happen with people like Cruz in the Boulevard, because you see it in a lot of movies at the period it&#39;s in Richard.</p>
                            <p>Dave! (35:02.31)
                            I think people did in the they did kind of cruise the Hollywood Boulevard, but the way that punks are portrayed and I am a big fan of L.A. punk rock, particularly of this era. My understanding of that time in L.A. punk is like they all just kind of sat around like reading Voltaire and shoplifting from thrift stores like nobody&#39;s out like sniffing glue and like holding knives up to ladies throats. Like what is this portrayal?</p>
                            <p>because it feels very much like a 65 year old man wrote it.</p>
                            <p>Bryan! (35:33.438)
                            Yeah, yeah, yeah. Like the only thing he knows is two years before the sex pistols came to America and fucked shit up. And like that&#39;s all anybody really knows about punk rock.</p>
                            <p>Dave! (35:38.752)
                            Mm.</p>
                            <p>Dave! (35:43.07)
                            It&#39;s like I watched a couple of weeks ago, I watched that David Cronenberg movie Existence and it is a movie. If people are not familiar, it is a movie kind of about virtual reality. It it was made right around the time of like moral panic about video games becoming more immersive, becoming a lot more violent and their effect on society. And I&#39;m watching the whole movie. I&#39;m like, what is it about this movie that is not clicking for me? Because I&#39;m not a big fan of David Cronenberg&#39;s later stuff, but.</p>
                            <p>Bryan! (35:48.094)
                            Yeah.</p>
                            <p>Dave! (36:10.77)
                            It just feels so off. And I was like, this feels like it was written by a man who doesn&#39;t know much about video games, but is still going ahead to write a movie about video games. It&#39;s like this feels so inauthentic and weird and judgy. And that is what this portrayal of punks feels like to me. It&#39;s like, first of all, you are just off in every possible way. It is also judgmental as fuck.</p>
                            <p>Bryan! (36:17.158)
                            No, no, it&#39;s like it&#39;s.</p>
                            <p>Bryan! (36:24.851)
                            Yeah.</p>
                            <p>Bryan! (36:33.266)
                            Yeah. This particular image of the punks in the ragtop classic car though is so fucking ubiquitous at the time. And it and it&#39;s like it makes me wonder if it&#39;s the product of something that somebody saw because you see it in this you see it in return of the living dead you see it in Savage streets.</p>
                            <p>Dave! (36:51.314)
                            I imagine it was probably just a lot of younger people that would do it. Like you were like we grew up in New Hampshire and you go to like Hampton Beach. A lot of that&#39;s where everyone would go on the weekends or weeknight or whatever in the summer. And it&#39;s just people going around and around and around and just kind of hanging out of their cars and being kind of boisterous. But that&#39;s kind of where it starts and stops. And I think if you don&#39;t if you&#39;re not young and you just kind of look at that, it&#39;s like, oh, they&#39;re all one thing to me. They&#39;re all punks out there just harassing people.</p>
                            <p>Bryan! (37:17.512)
                            Yeah.</p>
                            <p>Dave! (37:20.19)
                            And so my guess is like this, if this was a thing, I don&#39;t know, I did not grow up in California. But if it was that people would kind of just cruise the boulevard and or cruise the strip, as it were. Yeah, like you just sort of like if you were an older person, you&#39;d just be like, look at all these young punks out here. Like, they&#39;re all just kind of the same person to you, even though they are decidedly different, you know, socio economic groups, not to mention subcultures.</p>
                            <p>Bryan! (37:20.377)
                            Yeah.</p>
                            <p>Bryan! (37:30.307)
                            Well, the Beach Boys made a whole career out of it.</p>
                            <p>Bryan! (37:40.97)
                            Right, but...</p>
                            <p>Bryan! (37:46.63)
                            Yeah, these, these punks are the most aggressive punks I&#39;ve ever seen. They&#39;re like spitting at the cars next to them, like shouting and fucking flipping everybody off, throwing shit at people.</p>
                            <p>Dave! (37:56.362)
                            Yeah, this is, they are what Johnny Rotten portrayed himself as in the press.</p>
                            <p>Bryan! (38:02.182)
                            Yeah, yeah. And one point, it was like a guy and Jason Carter&#39;s like yelled screaming at him flipping him off is like a van with the side door open some, some chick flashing their boobs at him. It&#39;s absolutely nuts. But the song that&#39;s Oh, yeah, yeah. Remember, I was in the 90s and whip them out Wednesday because of Yeah, what the fuck was that radio station to play all the rock? But yeah, yeah. Oh, yeah, that was the summer that I worked down there. The fucking</p>
                            <p>Dave! (38:15.17)
                            I mean, that feels real Hampton Beach to me.</p>
                            <p>Dave! (38:21.574)
                            Oh, God.</p>
                            <p>Dave! (38:26.05)
                            God damn it, man.</p>
                            <p>Look, men are the fucking worst.</p>
                            <p>Bryan! (38:32.006)
                            Yeah, but uh yeah so but while this is playing the theme song is playing and the theme song is meant to be and it was it was by design in the script. It was supposed to be a punk rock song. It it&#39;s it is in the trailer that we just played. It does not sound punk at all. It&#39;s it sounds like it sounds to me like the band Pentagram.</p>
                            <p>Dave! (38:47.955)
                            It is not that.</p>
                            <p>Dave! (38:52.42)
                            It is twisted metal.</p>
                            <p>Dave! (38:57.578)
                            Yeah, yes.</p>
                            <p>Bryan! (38:59.438)
                            Or like any of the sort of like modern like stoner or occult rock bands, like it sounds like that. Like it&#39;s really fuzzy and a little psychedelic.</p>
                            <p>Dave! (39:08.738)
                            Who was the one, the ones that did magic on stage, that had like a magician in the band, who was that? Yes, it&#39;s like that. It is 100% that. I was like, this is at best, this band is like a fucking Slade cover band.</p>
                            <p>Bryan! (39:12.184)
                            Oh, sorcerer.</p>
                            <p>Bryan! (39:21.638)
                            Yeah. Oh man. Cuz yeah, they so they&#39;re a band. They&#39;re actually a band. It&#39;s a real band and they appear in the movie. They&#39;re they&#39;re yeah they they&#39;re called Shadow and they definitely they look like they were a glitter rock band that was called upon to be a punk band.</p>
                            <p>Dave! (39:27.138)
                            Yeah, both of the bands in this are real.</p>
                            <p>Dave! (39:35.842)
                            So this is why I&#39;m wondering, because this is kind of around the same time that like, that not, I guess, glam metal is kind of starting to pick up in Hollywood. You&#39;re getting bands like Motley Crue. Why didn&#39;t they just go with that? Because that is clearly what this is. Like this is, that would have fit so much better.</p>
                            <p>Bryan! (39:46.099)
                            Yeah.</p>
                            <p>Bryan! (39:52.102)
                            Oh, I know, that&#39;s the thing is the fact that they imported a band. This is the band Shadow is actually from Seattle, but fucking the Hollywood Boulevard like rock scene at the time was alive with like a million fucking different styles. Like I&#39;ve heard.</p>
                            <p>Dave! (40:03.934)
                            And if anybody is wondering why we are stuck on this, you can probably guess what we grew up listening to.</p>
                            <p>Bryan! (40:08.87)
                            Yeah, yeah. But like, I mean, I&#39;ve heard Vince Neal talk about Hollywood at this period, and it&#39;s like...</p>
                            <p>Dave! (40:13.77)
                            It just feels like a better fit. Like the whole thing feels like a better fit because the punk piece of this is so uncomfortable and nobody seems to know what to do with it through the whole fucking movie.</p>
                            <p>Bryan! (40:24.314)
                            Yeah, yeah. Let&#39;s let&#39;s get past this. We&#39;re obviously a little a little worked up. So yeah, these uh these loathsome punks arrive at the luxurious Holiday Inn and they&#39;re yes they are costed by the front door by the most nervous A cab. So yeah, he is the most nervous most midwestern cop I&#39;ve ever seen who demands their tickets and the way that he has he&#39;s like tickets tickets.</p>
                            <p>Dave! (40:34.614)
                            Where a police officer is taking tickets?</p>
                            <p>Dave! (40:40.054)
                            I mean, defund the police, am I right?</p>
                            <p>Dave! (40:53.207)
                            He has kind of a carnival barker, but like really uncomfortable with what he&#39;s doing.</p>
                            <p>Bryan! (40:53.723)
                            I need to see your-</p>
                            <p>Bryan! (40:57.854)
                            Yeah, I need to I need your tickets and I know they must have just slapped the police uniform on the craft services guy and said like here say this and so yeah and then yeah this punk is kind of like. Super super gay like yeah when he does speak.</p>
                            <p>Dave! (40:59.914)
                            Meanwhile, Dave Vaniens steps up to him.</p>
                            <p>Dave! (41:12.258)
                            Who is very gay, this guy? And like, finally I was like, that feels like authentic LA punk.</p>
                            <p>Bryan! (41:20.671)
                            that&#39;s a good one. Yeah, this element. Lead punk guy uh switchblade but it&#39;s actually in, yeah, up in Diane&#39;s room those punks like a bunch one who&#39;s got this kind of like he&#39;s really, really him every time she&#39;s on stage.</p>
                            <p>Dave! (41:29.558)
                            Real cool stuff.</p>
                            <p>Dave! (41:45.11)
                            Look, they had $600,000 for this movie. They are getting their fucking money&#39;s worth out of every single one of those people.</p>
                            <p>Bryan! (41:47.794)
                            Yeah. So up in Diane&#39;s room, her son Derek arrives with flowers, and she&#39;s not terribly interested in him.</p>
                            <p>Dave! (41:59.57)
                            Now, I&#39;m gonna tell you this, Pinky Tuskodera is not a good bomb.</p>
                            <p>Bryan! (42:03.878)
                            No, this is a major plot point that is going to pay off in the end. We find out that he&#39;s got some part in a TV show called space.</p>
                            <p>Dave! (42:10.674)
                            Well, first he gives her the bouquet of roses and then kisses her on the mouth. My note just says, gross.</p>
                            <p>Bryan! (42:14.759)
                            Yep.</p>
                            <p>Bryan! (42:18.615)
                            There&#39;s no other word for that. That&#39;s not appropriate. What is that? Oh yeah. So he&#39;s got a he&#39;s got a partner TV. He landed his partner TV show called Spaceship America or something like that and uh Diane couldn&#39;t care less.</p>
                            <p>Dave! (42:20.59)
                            What the fuck?</p>
                            <p>Dave! (42:33.384)
                            No, no, she&#39;s got a fucking big rock and roll show to do, I guess.</p>
                            <p>Bryan! (42:35.506)
                            big new age rock and roll show to do. So</p>
                            <p>Dave! (42:39.922)
                            I still don&#39;t understand who Blaze is. Is she a DJ?</p>
                            <p>Bryan! (42:44.67)
                            No, she&#39;s the host of Hollywood Hotline.</p>
                            <p>Dave! (42:47.606)
                            which is a call-in show for punk music.</p>
                            <p>Bryan! (42:52.042)
                            for new wave music. Get it right.</p>
                            <p>Dave! (42:53.586)
                            Okay, yeah, see, there&#39;s another thing that we conflate these things a lot in the sort of wider public imaginary, where it&#39;s like, oh, you know, punk bands, like Blondie. Like, no, they weren&#39;t a punk band. All of these things are like new wave and punk. They&#39;re not the same thing.</p>
                            <p>Bryan! (43:04.886)
                            Yeah, yeah. Oh, yeah. Oh, yeah. When people when people talk about like, yeah, CBGB 1977, yeah, punk bands like television and the talking heads. Yeah, so down at the show, we this is Hollywood Hotline. We learned that Diane is known as Blaze. They&#39;re going to count down the year&#39;s most popular new wave rock music. She addresses the crowd. Hi babies. It&#39;s time to slam down and it&#39;s time to slam down and get even.</p>
                            <p>Dave! (43:14.062)
                            Yeah, it&#39;s like, you know, soul music, like Tupac.</p>
                            <p>Dave! (43:30.29)
                            I, she has, this is all the enthusiasm of like a dinner show drag queen. She says, it&#39;s time to spin out and get even. It&#39;s time to boil your hair.</p>
                            <p>Bryan! (43:36.983)
                            Yeah.</p>
                            <p>Bryan! (43:41.502)
                            It&#39;s time to boil your hair. You know, that&#39;s a yeah. Hey, drop a load.</p>
                            <p>Dave! (43:44.334)
                            What does that mean? That&#39;s another one I&#39;m gonna start using. Hey man, hey man, don&#39;t boil your hair, okay? Oh no.</p>
                            <p>Bryan! (43:58.083)
                            It&#39;s going on our merch. So she&#39;s also taking calls for votes on the best song.</p>
                            <p>Dave! (43:59.203)
                            This is good. I don&#39;t think I can wear it if it says that.</p>
                            <p>Dave! (44:08.91)
                            But she&#39;s just so, like everything is so like, oh. Let&#39;s, I don&#39;t know if she&#39;s like trying to be like real kind of uninterested in the whole thing. Like, oh, it&#39;s just real, it&#39;s real cool to be uninterested. I&#39;m like.</p>
                            <p>Bryan! (44:18.818)
                            Oh, it&#39;s, it&#39;s that, yeah, it&#39;s that punk rock on we, you know, um, you know, who she, who her manner actually reminds me of more than anything is Ruth Roman in the baby. Like the way that she kind of carries herself is this kind of like no nonsense, tough chick.</p>
                            <p>Dave! (44:31.25)
                            Yes.</p>
                            <p>Dave! (44:36.014)
                            She does my note says Joan Crawford on K-Rock.</p>
                            <p>Bryan! (44:39.895)
                            Yeah. So.</p>
                            <p>Dave! (44:41.738)
                            It&#39;s very because it&#39;s very just like, hey, everybody, do you want to listen to some music or what? Like, I guess I don&#39;t know. Why aren&#39;t you excited about this? Hey, man, look, don&#39;t pull your hair over it. All right. So it&#39;s not that big a deal.</p>
                            <p>Bryan! (44:47.837)
                            Yeah.</p>
                            <p>Yeah. Shut up and boil your hair, right? So,</p>
                            <p>Bryan! (44:58.206)
                            Hey everybody, don&#39;t have a cow, right? Cowabunga!</p>
                            <p>Dave! (45:03.298)
                            God. 65 years old.</p>
                            <p>Bryan! (45:05.762)
                            So, the first caller wants to hear, we don&#39;t need no education, which is not the name of that song. It&#39;s not the name of that song, but we&#39;re gonna we&#39;re gonna go with it. We&#39;re just going with it, which yeah, by the latest in new wave punk rock music, Pink Floyd. So so everything&#39;s going great until she gets.</p>
                            <p>Dave! (45:12.411)
                            Also not the name of the song.</p>
                            <p>Dave! (45:19.23)
                            You mean that hot new wave song?</p>
                            <p>Dave! (45:27.818)
                            Oh man.</p>
                            <p>And then she&#39;s just like, it&#39;s New Year&#39;s Eve.</p>
                            <p>Bryan! (45:34.782)
                            Oh yeah. So then she gets a call from a dude who&#39;s using some unknown device to like disguise his voice and he sounds like a cross between Winslow Leech from Phantom of the Paradise and the she calls him the param. She calls him the Phantom. Maybe maybe because he does.</p>
                            <p>Dave! (45:46.31)
                            Is that what that what she, cause she makes a comment, she calls him the phantom. Is that what she&#39;s referring to phantom of paradise? Cause he does sort of sound like that. Did you, did you learn why that he sounds this way?</p>
                            <p>Bryan! (45:59.179)
                            Uh no, well he does that just to disguise his voice.</p>
                            <p>Dave! (46:02.422)
                            Well, no, he had so they had originally they had like an actual voice, like the thing when people would have like tracheotomies or they would have like part of their like the throat removed essentially. And it&#39;s that thing that you like put to your throat and it makes the vibration, it sort of like translates it for you. It didn&#39;t work, though, in the movie. So they were like, what the fuck are we going to do? And he&#39;s like, don&#39;t worry, I got this. And he just does it with his voice. And they were like, I guess good enough.</p>
                            <p>Bryan! (46:12.927)
                            Yeah, yeah.</p>
                            <p>Bryan! (46:16.838)
                            Right.</p>
                            <p>Yeah, but I, I know because so what they</p>
                            <p>Bryan! (46:28.118)
                            Yeah. Yeah. But they give him a little device that looks it looks like um Peter Frampton used it on Frampton Comes Alive that that yeah that that yeah it&#39;s a thing that you had um uh Roger Trout yeah Roger Troutman and Zap what it actually is is the device that vibrates sends vibrations into your mouth and then you form the causes brain damage. Yeah, Roger Troutman from Zap built an entire <em>**</em> career on it.</p>
                            <p>Dave! (46:34.26)
                            Akazu.</p>
                            <p>Oh, the vocoder. Wah wah wah. It&#39;s a vocoder.</p>
                            <p>Dave! (46:49.078)
                            Yeah, they don&#39;t make them anymore because it caused brain damage.</p>
                            <p>Bryan! (46:56.574)
                            but it&#39;s a fucking awesome device but also</p>
                            <p>Dave! (46:58.53)
                            But yeah, it doesn&#39;t work, so he just does it with his own voice, because they were like, well, gotta keep going. This is a canon movie.</p>
                            <p>Bryan! (47:03.214)
                            I love it. I love I love when he talks because he&#39;s like I&#39;m evil.</p>
                            <p>Dave! (47:07.314)
                            Oh, it&#39;s so bizarre. Like the even the threatening phone calls just so boring. Like today, buddy, spit it out.</p>
                            <p>Bryan! (47:16.187)
                            Yeah, my other comment was, he also sounds like the Donald Duck caller from New York Ripper.</p>
                            <p>Dave! (47:20.842)
                            Yes, that&#39;s what I mean, is like there there&#39;s these weird kind of illusions and like nods to either just like Italian horror or is yellow because they&#39;re not the same thing, everybody. And like just like that, the New York Ripper thing, because that&#39;s the same year, isn&#39;t it? Or is New York Ripper 70?</p>
                            <p>Bryan! (47:37.298)
                            New York Ripper, I think is the I believe it&#39;s 81 or 82.</p>
                            <p>Dave! (47:42.062)
                            That movie is gross.</p>
                            <p>Bryan! (47:44.182)
                            Yeah, yeah, I wrote I wrote about that one a little ways back. That one is pretty is pretty is pretty upsetting fucking fantastic. Yeah, so he introduces himself as evil and he pledges to kill someone at midnight someone she knows. But here&#39;s the thing, he already killed her.</p>
                            <p>Dave! (47:48.445)
                            Awesome soundtrack.</p>
                            <p>Dave! (47:59.466)
                            Yeah, he&#39;s already killed somebody. This is where their whole fucking thing goes, it gets derailed before it even starts, because it&#39;s like he has this very murky strategy for murdering people. And a motive will kind of get to you at the end a little bit.</p>
                            <p>Bryan! (48:05.972)
                            Yeah.</p>
                            <p>Bryan! (48:13.19)
                            Yeah, now I got a thing about it because I do. This is one thing about the movie that I do genuinely like and I don&#39;t think that I&#39;ve actually seen it anywhere else because they really they don&#39;t mask it. He puts a mask on towards the end, but his identity.</p>
                            <p>Dave! (48:27.762)
                            I have a big issue with that, actually, the fact that they show you his face. Because this is something that people seem to love about. What was that other one that everyone was all up in arms about that we did? With Radish. Yeah, so in that one, they show you the guy&#39;s face immediately. And the killer is fucking nobody. It doesn&#39;t matter who he is. You&#39;ve never seen him before. Now, in Halloween, Michael Myers is as is as is. Nobodies is a part of the story.</p>
                            <p>Bryan! (48:31.143)
                            It&#39;s weird, right?</p>
                            <p>Bryan! (48:41.532)
                            Oh, final exam.</p>
                            <p>Dave! (48:55.982)
                            It&#39;s what makes him scary. It&#39;s that he is no one. He could be anyone. That&#39;s the whole point is that he is kind of a faceless evil that blows into town, kills everybody and fucking leaves. That&#39;s the thing that makes him scary. That fucking guy in Final Exam, I don&#39;t know who he is. They don&#39;t act like we&#39;re supposed to know who he is. So it doesn&#39;t fucking matter who he is. Like, it&#39;s just like, it&#39;s kind of dumb. Like you either have to make it a part of the character, make it a part of the story or don&#39;t do it. Cause it&#39;s like, okay, why do I give a shit?</p>
                            <p>Bryan! (48:58.815)
                            Mm-hmm.</p>
                            <p>Bryan! (49:21.622)
                            Yeah. Yeah. But but again, that&#39;s the detail in this that is really more like a jello than anything else because it&#39;s he there&#39;s it turns out there&#39;s a <em>**</em> Byzantine plot at the end of it and there&#39;s a whole thing but like the</p>
                            <p>Dave! (49:36.362)
                            That&#39;s real dumb because we get to the end and I&#39;m like, OK, I guess.</p>
                            <p>Bryan! (49:40.21)
                            Yeah, but the thing that I do like about it is they show you his face. They give him a personality. They and they just dangle that out there for the whole thing. And then at the end, they do this thing that we&#39;re going to get to that I was like, oh, okay, I can. I&#39;ve never seen that before. I can get down with it.</p>
                            <p>Dave! (49:59.31)
                            Alright, I don&#39;t think we&#39;re gonna have the same opinion about the end of this movie.</p>
                            <p>Bryan! (49:59.432)
                            So yeah.</p>
                            <p>Bryan! (50:03.438)
                            No, no, no. So now back at back at Hollywood Hotline, Blaze intro. Yeah, Blaze introduces the yeah, she introduces the band Shadow, who are the band that actually plays New Year&#39;s Evil. And the audience slam dances to this jam.</p>
                            <p>Dave! (50:07.334)
                            Yeah, meanwhile, back at our punk club at the Holiday Inn.</p>
                            <p>Dave! (50:19.518)
                            The singer has a leather half-shirt on. He looks real cool. That&#39;s cool with a K.</p>
                            <p>Bryan! (50:22.302)
                            I&#39;m not sure. Yeah. And now, elsewhere.</p>
                            <p>Dave! (50:29.066)
                            And everyone&#39;s moving so slowly. Like the audience is like so lethargic.</p>
                            <p>Bryan! (50:34.618)
                            Yeah, you know what they saw is somebody saw punk rock the movie and was like, Oh, that&#39;s slam dancing. Okay. So that&#39;s how we&#39;ll have everybody. Everybody in our audience do the thing where they&#39;re like just jumping up and down and like bouncing into each other, strangling each other.</p>
                            <p>Dave! (50:48.834)
                            But it kind of looks like everyone&#39;s just sort of like in a wave pool. Like everyone&#39;s just kind of bumping into each other a little bit. No, it&#39;s real sluggish. Oh, yeah.</p>
                            <p>Bryan! (50:54.386)
                            Yeah, and it&#39;s gonna it&#39;s gonna get even worse in a minute, but elsewhere, our caller evil gears up and sneaks into a mental hospital, which is also, which is super, super offensive, which is also watching the Hollywood hotline broadcast for some reason. Everybody just loves new wave music.</p>
                            <p>Dave! (51:04.67)
                            Oh man, the mental hospital. This shit is offensive.</p>
                            <p>Dave! (51:12.47)
                            Yeah, they&#39;re mentally ill, they&#39;re not animals.</p>
                            <p>It&#39;s like that, yeah, you remember that cramps live performance from the, it&#39;s like that.</p>
                            <p>Bryan! (51:19.334)
                            I was gonna get some ideas in my notes is when the cramps performed in a mental hospital You can get bootlegs of it. I believe it&#39;s also on YouTube. It&#39;s a pretty good It&#39;s a pretty good performance</p>
                            <p>Dave! (51:26.462)
                            It&#39;s very insensitive. It is. That&#39;s a peak. Peak craps.</p>
                            <p>Bryan! (51:34.207)
                            So evil emerges dressed as a doctor. Oh yeah, they&#39;re like, oh hey, who are you? And he&#39;s like, I&#39;m the new guy. Sorry I&#39;m late.</p>
                            <p>Dave! (51:35.754)
                            Also, these people suck at doing their jobs.</p>
                            <p>Dave! (51:41.49)
                            Yeah, I actually have a note that says, I hope these nurses get killed first.</p>
                            <p>Bryan! (51:46.318)
                            Well, Nurse Taffy is going to get it pretty bad.</p>
                            <p>Dave! (51:48.455)
                            Yeah, I have a second. It&#39;s good news. It looks like they do.</p>
                            <p>Bryan! (51:50.878)
                            Hahahaha</p>
                            <p>Dave! (51:53.586)
                            And he shows up and he says, I&#39;m temporary help. Now listen, temporary help at a psychiatric hospital is not a thing.</p>
                            <p>Bryan! (52:00.87)
                            Uh, well, when I worked there, we had a lot of temporary help.</p>
                            <p>Dave! (52:04.818)
                            I mean, you have people that don&#39;t work there for very long, but you don&#39;t, like, call someone from another hospital to come on down.</p>
                            <p>Bryan! (52:11.318)
                            Oh yeah, that was definitely not something that happened. Like nobody came down from fucking the state hospital to work at us. They just sent us all their patients. So he immediately launches. He runs into this nurse, nurse Taffy, and he immediately launches into a flirt sesh. He&#39;s got a radio for some reason.</p>
                            <p>Dave! (52:28.534)
                            He&#39;s got a he&#39;s got a ghetto blaster. That is a boombox. Yeah. He brought his own music.</p>
                            <p>Bryan! (52:32.243)
                            Yeah. Oh yeah. 12 batteries at</p>
                            <p>Dave! (52:39.414)
                            And then they start getting drunk and dancing like you do at work.</p>
                            <p>Bryan! (52:43.334)
                            Yep. So yeah, this is an actress named Taffy O&#39;Connell, and she also is on the same episodes as Pinky Tuscadero. She was, they were rivals apparently. And they, oh, in real life they were, yeah, yeah. But like Ian, they pitched us, apparently this Pinky character was such a fucking big deal that they immediately like went to spin off to another show about her.</p>
                            <p>Dave! (52:52.201)
                            Because they were friends.</p>
                            <p>No, in real life they were friends.</p>
                            <p>Dave! (53:08.002)
                            And I don&#39;t get it, because she kind of sucked. Suzy Quattro was cool as shit. She should have had her own fucking show. She was tiny and wore a leather jumpsuit, and she played rock and roll. And I mean, like, real rock and roll, like not, you know, not Shadow.</p>
                            <p>Bryan! (53:12.298)
                            Yeah. Yep.</p>
                            <p>Mm-hmm. Really good.</p>
                            <p>Yep, there&#39;s no there&#39;s no Joan Jett without leather Tusk Adaro.</p>
                            <p>Dave! (53:24.466)
                            Yeah, she was awesome. Well, there was because it was she was still in the runaways. So maybe there was no leather Tuscadero without Joan Chadd.</p>
                            <p>Bryan! (53:29.449)
                            Yeah.</p>
                            <p>Bryan! (53:33.638)
                            Yeah, I suppose. Anyways, in a private room with some Dixie cups for Champagne, he puts on some smoky blues music, which turns out to be performed by the punk rock band Shadow back.</p>
                            <p>Dave! (53:39.583)
                            Mm-hmm.</p>
                            <p>Yeah.</p>
                            <p>Dave! (53:46.949)
                            Yeah, you know, our punk rock man is a bluesy slow jam.</p>
                            <p>Bryan! (53:51.344)
                            Hollywood hotline while the very the very punk rock crowd lazily slam dances into one another</p>
                            <p>Dave! (53:56.418)
                            They do, like, I swear to God, nobody in this scene knows what to do with themselves. They all look like they&#39;re having a seizure. Everyone is just sort of like waving back and forth, like big shoots of grain. They&#39;re just like, sort of lazily moving.</p>
                            <p>Bryan! (54:07.506)
                            right, right. They&#39;re coming. They&#39;re, they, they&#39;re coming down. They&#39;re coming down. They got to go out and call their guy to get another eight ball. It&#39;s, it&#39;s a whole fucking thing. They&#39;re just so lethargic and tired.</p>
                            <p>Dave! (54:17.122)
                            But like, they&#39;re just like, well, no, are we supposed to dance? And they&#39;re like, no, it&#39;s a blues song. They&#39;re like, but you said they&#39;re a punk band and everyone&#39;s just kind of like, Walt sing a little bit. It&#39;s very, very strange.</p>
                            <p>Bryan! (54:24.233)
                            Yeah.</p>
                            <p>Mm hmm. Yeah. Lee Ving from fear was a big blues guy, though. Yeah.</p>
                            <p>Dave! (54:32.874)
                            Yeah, he was. And I&#39;ll tell you what, not a good blues singer. Great singer for fear.</p>
                            <p>Bryan! (54:36.53)
                            Not a good blues singer. He played. Oh, yeah, he plays a he plays a blues singer on an episode of who&#39;s the boss. And yep, makes eyes. It&#39;s a pretty fucking</p>
                            <p>Dave! (54:42.642)
                            He sure does. I love leaving acting career because it was always in the places you least expect to find him. Clue.</p>
                            <p>Bryan! (54:53.212)
                            Yeah.</p>
                            <p>Dave! (54:56.246)
                            But he was everywhere but for a very short time, and that&#39;s for a reason.</p>
                            <p>Bryan! (55:00.526)
                            Yeah, he really he really leaves an impression though. So backstage, the police show up and they&#39;ve been called because of evil&#39;s call to blaze. And I got to say it&#39;s nice for a movie to give us an accurate portrayal of the police for once because they couldn&#39;t give a shit. They see you see these punks are the people who enable them to create a problem and then they complain about it.</p>
                            <p>Dave! (55:13.63)
                            Yeah. These guys suck.</p>
                            <p>Dave! (55:25.502)
                            I mean, that sounds about right. That sounds like a talking point I&#39;ve heard not that long ago.</p>
                            <p>Bryan! (55:27.794)
                            Yeah, that&#39;s it.</p>
                            <p>Bryan! (55:31.438)
                            Yeah, the eight, no, hey, this wouldn&#39;t be happening if you didn&#39;t dress like that. This wouldn&#39;t be happening if you didn&#39;t have drag queens performing. Yeah, the.</p>
                            <p>Dave! (55:35.513)
                            Mm-hmm.</p>
                            <p>And then he says, her manager says, this show is very important to her. And I thought, what show? What is this? I don&#39;t know what that means.</p>
                            <p>Bryan! (55:45.079)
                            It&#39;s Hollywood Hotline! It&#39;s the biggest rock show! I&#39;ve read some...</p>
                            <p>Dave! (55:51.306)
                            Why is it at a fucking mid-tier hotel chain?</p>
                            <p>Bryan! (55:55.006)
                            they apparently the biggest rock show in the world is shot at the Holiday Inn and on Ventura somewhere. Yeah. And this so the 80s were a particularly conservative period but on top of that, Canon were arch conservative and this sort of vein of otherness runs through all of their movies like all of them like that.</p>
                            <p>Dave! (56:14.059)
                            Mm-hmm.</p>
                            <p>Dave! (56:18.862)
                            But it&#39;s always a very vague kind of nebulous otherness. It&#39;s like, you&#39;re either a kind of middle class white man or working class white man, or you&#39;re everybody else.</p>
                            <p>Bryan! (56:30.251)
                            Or you&#39;re a member of a giggling psychotic gang that wears a lot of face makeup. And yeah, if every, the bad guys in like all of their movies from like 1980 to 1987 all wear face paint and like leather with like chains jangling and they&#39;re just accosting people on the street, shaking them down.</p>
                            <p>Dave! (56:48.894)
                            And at least one of them talks like one of the Weasels from Roger Rabbit. Like, yeah, see, why can&#39;t we take a purse, boss? Like that kind of thing. God, I&#39;m good at that.</p>
                            <p>Bryan! (56:53.381)
                            Hehehe, yeah.</p>
                            <p>Bryan! (56:57.666)
                            Yeah, it&#39;s like, yeah, I know. See, this is why we got to do death with Sri. So you could do the giggler. So yeah, but we&#39;re still, we&#39;re still really early in the Golan Globus period of canon, and you&#39;re already seeing it.</p>
                            <p>Dave! (57:03.726)
                            Oh, God, that movie. I don&#39;t think I can.</p>
                            <p>Dave! (57:14.922)
                            Like, I just remember when, you know, I think probably in the early 2000s, it&#39;s like people were like, oh, the 80s were just so great. It&#39;s like, no, man, they fucking sucked. The 90s weren&#39;t so hot either. Like we get a like marginally better with each decade, but not that much.</p>
                            <p>Bryan! (57:26.999)
                            It was...</p>
                            <p>Bryan! (57:31.302)
                            If if yeah, see given if yeah, somebody was like, would you like to take this time machine back to 1985 or 1995, I&#39;d go back to 1995 because at least we get the X files. Yeah, I can still drive so back at the luxurious uh actually no, I&#39;m getting ahead of</p>
                            <p>Dave! (57:40.974)
                            Man, at least I know where to go. At least I remember how to get places.</p>
                            <p>Bryan! (57:54.078)
                            Back at the, no, god damn, I&#39;ll cut this shit out. With the cops gone, Blaze calls back to her dressing room to try and talk to Derek. She&#39;s looking for Yvonne, who is supposed to be at the show, but you see, she can&#39;t be there because she&#39;s dead. Now, Derek...</p>
                            <p>Dave! (58:07.694)
                            Mm-hmm. But Derek has something very important he wants to tell her. She doesn&#39;t care, because she&#39;s blazed and this is a very important rock show.</p>
                            <p>Bryan! (58:15.99)
                            Yeah, and he is vamping like a crazy person.</p>
                            <p>Dave! (58:19.074)
                            I gotta give it to this guy, he&#39;s 17 years old when they made this. Also, he, obviously his most memorable performance is Killer Clowns, and that is a good seven years later, he looks exactly the same.</p>
                            <p>Bryan! (58:32.254)
                            Yeah. Exactly the same. So, yeah, also he downs a huge handful of like big red pills. Yeah, they do. They look delicious. So back in the hospital, evil is.</p>
                            <p>Dave! (58:42.39)
                            Yeah, they look like jelly beans.</p>
                            <p>Dave! (58:46.606)
                            I wish I had jelly beans.</p>
                            <p>I like that. I&#39;ll try candy.</p>
                            <p>Bryan! (58:49.762)
                            I had some. Go so well right now. Listening to me chew into my microphone. So back in the hospital, Evil is making out with Taffy the nurse. And as midnight hits in New York City, Evil raises a knife, stabs the shit out of Taffy the nurse as the ball drops. So back at</p>
                            <p>Dave! (59:07.829)
                            Yeah.</p>
                            <p>Man, the 80s had a real thing for people fucking in hospitals.</p>
                            <p>Bryan! (59:15.099)
                            Oh yeah, man. Yeah, there was the</p>
                            <p>Dave! (59:15.926)
                            Like if ever there was a place where people had a job to do, it&#39;s a hospital and yet.</p>
                            <p>Bryan! (59:19.542)
                            was, it was, it was, it was, it</p>
                            <p>Dave! (59:25.666)
                            I&#39;m thinking Halloween too. I&#39;m thinking Friday. Is it three or four? Five, maybe it&#39;s five.</p>
                            <p>Bryan! (59:31.075)
                            I don&#39;t know. Yeah, I don&#39;t know. Yeah. But yeah, but like, I mean, like, I think of nurses and I think of like women who&#39;ve been handling like bodily fluids all day, you know?</p>
                            <p>Dave! (59:39.062)
                            Yeah. That is the last place you want to fuck anybody. Maybe not the last place, but it&#39;s one of them. It&#39;s on the list. Yeah.</p>
                            <p>Bryan! (59:42.226)
                            Yeah. It&#39;s one of them, it&#39;s up there. Like the dump would be in our place. You don&#39;t wanna fuck somebody.</p>
                            <p>Dave! (59:51.454)
                            But to each their own, I don&#39;t want to kink shame. If you&#39;re into fucking at the dump, get out there and get it. God bless you.</p>
                            <p>Bryan! (59:53.595)
                            No cake shaving. You want to fuck at the dump.</p>
                            <p>Bryan! (59:59.359)
                            So back at Hollywood, you dumb fuckers. So back at Hollywood.</p>
                            <p>Dave! (01:00:11.182)
                            I&#39;m gonna go.</p>
                            <p>Dave! (01:00:15.619)
                            This is going great.</p>
                            <p>Bryan! (01:00:18.77)
                            Ah, that one&#39;s gone. That one&#39;s going on the merch. So back in Hollywood hotline, there&#39;s a new band on</p>
                            <p>Dave! (01:00:22.802)
                            Ugh.</p>
                            <p>I don&#39;t tell you, I&#39;ve been working on a t-shirt design. You know what it&#39;s gonna say on the back? You dumb fuckers.</p>
                            <p>Bryan! (01:00:32.616)
                            Oh no. Oh no. So, huh. So now back at Hollywood Hotline, there&#39;s a new band on stage. You see, they&#39;re decidedly more punk rock than shadow, but just a little bit better. Um, Evil calls back to tell Blaze about killing Taffy and he plays back the recording of him killing her and then her body is found by one of the other nurses, but her shoe is found first. This is a detail that&#39;s going to come back a little bit later.</p>
                            <p>Dave! (01:00:42.186)
                            Yeah, they are a little bit better.</p>
                            <p>Bryan! (01:01:00.79)
                            So we&#39;re still really early in the slasher golden age, but this movie takes a really, really weird approach to the slasher convention of a masked killer. So we talked about this earlier. Evil&#39;s going to wear a mask eventually, but he starts for no reason. And a lot of this, most...</p>
                            <p>Dave! (01:01:14.574)
                            For no reason. Because he&#39;s going to wear a mask at the very end.</p>
                            <p>Bryan! (01:01:20.794)
                            Yeah, the real the real mystery about him is his identity, which will be the carrot on the stick for the rest of the movie.</p>
                            <p>Dave! (01:01:24.906)
                            Which it&#39;s not a mystery because they never set it up.</p>
                            <p>Bryan! (01:01:29.486)
                            No, well, sort of just with a little offhand remark that pays off a little later on, but in the end, it still doesn&#39;t fucking matter. It doesn&#39;t matter at all. So, but still, at the same time, I can&#39;t think of another slasher movie that does this. That does the, I&#39;m going to go maskless and then I&#39;m going to put a mask on at the end.</p>
                            <p>Dave! (01:01:43.97)
                            But it does what?</p>
                            <p>Dave! (01:01:50.43)
                            It does seem an odd choice.</p>
                            <p>Bryan! (01:01:52.114)
                            Yeah. So back at the luxurious Holiday Inn, Derek is going crazy in his mother&#39;s dressing room. He has a switchblade, you see.</p>
                            <p>Dave! (01:01:55.125)
                            Mm-hmm.</p>
                            <p>Dave! (01:01:59.762)
                            in the strangest way.</p>
                            <p>Bryan! (01:02:03.034)
                            He uses the knife to cut up one of her stockings, he pulls it over his face while... No, he struggles to get it on.</p>
                            <p>Dave! (01:02:07.074)
                            But it clearly does not fit over his head very well because he has to cut it a little bit in order to get it on his head. You can tell that like he&#39;s really struggling.</p>
                            <p>Bryan! (01:02:18.326)
                            And he&#39;s monologuing about how his mom thinks he has a mental disorder. She might be right.</p>
                            <p>Dave! (01:02:22.751)
                            Yeah, I...</p>
                            <p>Bryan! (01:02:25.234)
                            Yeah. So, back down at the show, yeah, like, is he, is he, I don&#39;t know, trying to dress up like her or something like that? Like, it&#39;s just him being crazy.</p>
                            <p>Dave! (01:02:26.998)
                            And then he jams a big pin through his ear.</p>
                            <p>Dave! (01:02:37.266)
                            My understanding is that all the script really said was like, basically like he Derek goes crazy. And so he was like, OK, I guess I&#39;ll do this. And you know what? For a 17 year old good on you, buddy. You&#39;re selling something. I&#39;m not sure if it&#39;s crazy, but it&#39;s something.</p>
                            <p>Bryan! (01:02:48.746)
                            hell of a job.</p>
                            <p>Bryan! (01:02:53.354)
                            So back down to the show, Shadow is on stage playing a heavy metal song while Pinky Tuscadero dances all sexy with him.</p>
                            <p>Dave! (01:03:00.954)
                            Oh, I didn&#39;t like this part, because again, it&#39;s just like, this feels like someone&#39;s mom. I mean, I guess it is, but it just looks like it too. Like she&#39;s supposed to be like, you know, you&#39;re supposed to get like the kind of like a lead of forward, like hot, young, sexy, big hair, short skirts, you know, whatever the eighties thought was sexy. That&#39;s what you&#39;re supposed to get. That is not what she&#39;s giving though. She&#39;s giving like middle-aged woman who&#39;s not super comfortable in heels anymore.</p>
                            <p>Bryan! (01:03:05.31)
                            Yeah. Up in the dressing.</p>
                            <p>Bryan! (01:03:21.034)
                            Mm.</p>
                            <p>Bryan! (01:03:25.05)
                            No.</p>
                            <p>Dave! (01:03:31.262)
                            And the skirt is just like this, just is not cool for her.</p>
                            <p>Bryan! (01:03:31.358)
                            She said...</p>
                            <p>She&#39;s like she&#39;s like two Long Island ice teas in Long Island, you know?</p>
                            <p>Dave! (01:03:39.934)
                            It is, it&#39;s like I went to see Billy Joel and Stevie Nicks over the summer. It was that, it was all that, that was the whole audience. Just like you haven&#39;t been out of your house in eight months, you have four kids at home, this is your one night to cut loose. So you put on something that you haven&#39;t worn in a couple of years, still fits, but just barely. That&#39;s this, that&#39;s Blaze.</p>
                            <p>Bryan! (01:03:45.039)
                            Yeah.</p>
                            <p>Bryan! (01:03:48.511)
                            My god.</p>
                            <p>Bryan! (01:04:02.282)
                            That&#39;s yeah, that&#39;s blaze in a nutshell. Elsewhere in the city, evil is prepping a crappy fake mustache. Now.</p>
                            <p>Dave! (01:04:09.787)
                            Oh man, it is like the cop at the end of Sleepaway Camp.</p>
                            <p>Bryan! (01:04:14.234)
                            it is the most ridiculous mustache aside from the sleep away camp mustache. The police meanwhile informed blaze that evil wasn&#39;t lying and that they found the nurse and comes to she cut they come to the conclusion or rather she comes to the conclusion that evil also killed Yvonne. So he did.</p>
                            <p>Dave! (01:04:33.974)
                            But they don&#39;t really know, like, I mean, I guess if you&#39;re like, well, here&#39;s a guy who&#39;s taunting you, it&#39;s a safe assumption that he probably killed your friend. But like, she&#39;s sort of unduly fearful of him throughout the whole movie. It&#39;s like he&#39;s just calling you on the phone. He&#39;s killing these other ladies. Maybe they should be afraid.</p>
                            <p>Bryan! (01:04:50.918)
                            Yeah, they come to a weird conclusion about him a little later on that it&#39;s like, man, you guys are reaching to connect the dots here but evil is now in a leisure suit and fake mustache and shows up to a bar where white people are dancing. Yeah, now being a serious man of leisure. He smokes a tiny cigarillo and then he catches the eye of a lone lady at the bar and impresses her with his watch seems</p>
                            <p>Dave! (01:05:08.204)
                            Oh man.</p>
                            <p>Dave! (01:05:19.186)
                            And I actually have a note that says, being a young single woman in the 80s seems like an absolute fucking minefield of obnoxious, fragile men. Like everybody is Larry from Three&#39;s Company. Just fucking dancing up on you like, hey lady. Like God, just wanna have a fucking drink.</p>
                            <p>Bryan! (01:05:28.531)
                            Yeah.</p>
                            <p>Bryan! (01:05:32.152)
                            That&#39;s a regal beagle.</p>
                            <p>Bryan! (01:05:37.958)
                            Yeah. So, uh, yeah, he says the seems the more expensive they are, the quicker they go out of whack. Which made me think, which made me think sometimes things that are expensive are worse. So back at the show, the cops theorize that evil is going to kill someone every time the clock strikes midnight in a new time zone.</p>
                            <p>Dave! (01:05:46.19)
                            Ugh. Sometimes things that are expensive are worse.</p>
                            <p>Dave! (01:05:59.77)
                            Now wouldn&#39;t that imply that he&#39;s only going to kill what is it three people four people How many times what&#39;s other four three?</p>
                            <p>Bryan! (01:06:07.427)
                            Well, there was, let&#39;s see, so there&#39;s Eastern, there&#39;s Central, there&#39;s Mountain, and there&#39;s Pacific.</p>
                            <p>Dave! (01:06:11.362)
                            That&#39;s four.</p>
                            <p>Now he kills way more than that.</p>
                            <p>Bryan! (01:06:16.626)
                            He kills Yvonne Taffy the nurse does he? Wow. All right.</p>
                            <p>Dave! (01:06:18.987)
                            He kills eight people.</p>
                            <p>Yep, so why set up this whole thing from the very beginning when you have already undermined your fucking premise, why set it up at all? Why not just be like, he&#39;s killing and calling it? Like just do that.</p>
                            <p>Bryan! (01:06:31.193)
                            I know, but they...</p>
                            <p>Bryan! (01:06:35.046)
                            Yeah, you know what it is, is it seems like they were the writers and the producers were really kind of struggling with the with it, like they were really hung up on the one detail of the successful slasher movies that came before and it&#39;s the holiday. It&#39;s the holiday theme. And so they were like, shit, we got to tie this back to the holiday and it&#39;s like, no you fucking don&#39;t I mean it&#39;s</p>
                            <p>Dave! (01:06:47.862)
                            Holidays. Yep.</p>
                            <p>Dave! (01:06:54.454)
                            Look, you just do what fucking My Bloody Valentine does. You make a movie and then you retroactively name it something that&#39;s holiday themed.</p>
                            <p>Bryan! (01:07:01.822)
                            Yeah. You don&#39;t have to fucking do it. But so at the white people bar, Evil continues to woo this lady. You see, he&#39;s got to go. He just dropped in for a drink. He has to head over to a party at Eric Estrada&#39;s house. You impressed there? So.</p>
                            <p>Dave! (01:07:14.55)
                            Yeah, big party, big party. Mm hmm.</p>
                            <p>Dave! (01:07:20.906)
                            And then he says, it&#39;s a command performance, a command performance by who? By Eric Estrada for you or for you to Eric Estrada. Like, why would he care?</p>
                            <p>Bryan! (01:07:26.918)
                            he&#39;s he no you know what you know what he&#39;s you know he&#39;s doing is he&#39;s inviting all these people over and he&#39;s just going to perform soliloquies from Shakespeare and everybody who&#39;s invited has to go and they have to watch him do these monologues.</p>
                            <p>Dave! (01:07:41.526)
                            Yeah, this is like when that when your one friend with the kind of long hair shows up at the party and takes out his acoustic guitar to play you some white blues.</p>
                            <p>Dave! (01:07:53.93)
                            That one is a strong bad reference. That&#39;s not mine. So if it didn&#39;t land, blame them.</p>
                            <p>Bryan! (01:07:59.71)
                            I&#39;m going to do that. Yeah, So, at Hollywood Hotline, the named Made in Japan plays a they&#39;re like a Buzzcocks,</p>
                            <p>Dave! (01:08:09.354)
                            Yeah, it&#39;s like Nick Lowe, but without any of the talent or charm.</p>
                            <p>Bryan! (01:08:12.862)
                            Yeah. As a matter of fact, the riff on this song is almost identical to the Dickie&#39;s version of Silent Night. It was a little, it&#39;s a little suspicious.</p>
                            <p>Dave! (01:08:19.542)
                            Yep. Yeah, they&#39;re very, they&#39;re very, they&#39;re very Buzzcocks. Very the jam, but like with more energy, I guess.</p>
                            <p>Bryan! (01:08:25.138)
                            Yeah. So outside the white p...</p>
                            <p>A little bit. I don&#39;t know how much you get how much more energy than the jam you get. Outside the white people bar, evils lady pickup demonstrates a little bit of caution and awareness that we&#39;re not used to seeing in a slasher movie. She brings along a friend because she&#39;s about to fuck off with a total stranger. Now they&#39;re both about to die but it&#39;s an afer effort from me. Now back at the show blazing the yeah at the Howard Johnson.</p>
                            <p>Dave! (01:08:47.229)
                            Mm-hmm.</p>
                            <p>Dave! (01:08:52.226)
                            Back to the Radisson.</p>
                            <p>Dave! (01:08:56.046)
                            Oh God.</p>
                            <p>Bryan! (01:08:56.222)
                            plate plays on the cop examine the recording to see if she recognizes anything about him.</p>
                            <p>Dave! (01:09:01.246)
                            He says, I can&#39;t tell. He&#39;s apparently using some kind of voice processor. Yeah, it&#39;s a kazoo.</p>
                            <p>Bryan! (01:09:07.892)
                            Now, a producer gives her an out as they&#39;re getting closer to midnight in Chicago. She refuses to sit this one out because this is what the killer wants, which is a bit presumptuous if you ask me.</p>
                            <p>Dave! (01:09:18.014)
                            And look, this is a very big concert. This is the big rock and roll concert of the year.</p>
                            <p>Bryan! (01:09:20.831)
                            biggest of the year.</p>
                            <p>Yeah, all the young, the all the youngsters and the whippersnappers have been waiting all year for this.</p>
                            <p>Dave! (01:09:30.742)
                            They have, and you know they&#39;re sniffing glue and they&#39;re drinking Manna Sheffetz wine in the parking lot. Now, what do you, you know, they&#39;re doing kid stuff, you know?</p>
                            <p>Bryan! (01:09:34.304)
                            Yep.</p>
                            <p>Bryan! (01:09:38.634)
                            Mm hmm. And they&#39;re terrorizing the neighbors. They&#39;re stepping on the grass when they shouldn&#39;t be. I know. So on the way to Erica strata&#39;s party, the girl he the guy no, it&#39;s fucking the best. The girl he picked up is being really happy. And she has the worst voice that I&#39;ve ever heard.</p>
                            <p>Dave! (01:09:44.507)
                            Oh, it just feels, oh man.</p>
                            <p>Dave! (01:09:52.216)
                            I love this part though.</p>
                            <p>Dave! (01:09:59.114)
                            Yeah, my actually my note says I kind of want to kill this woman too.</p>
                            <p>Bryan! (01:10:03.738)
                            Her voice is like nails in my ears.</p>
                            <p>Dave! (01:10:06.37)
                            You know, she reminds me of she&#39;s a Jersey Salem&#39;s lot, the original TV movie. She reminds me of Bonnie Sawyer, the woman I can&#39;t remember her name. I think she was on a TV show. Blonde hair. She might actually be her for all I know. She looks a lot like her. She&#39;s like that, or she&#39;s just like kind of awkward and just talks nonstop.</p>
                            <p>Bryan! (01:10:10.931)
                            Yeah.</p>
                            <p>Bryan! (01:10:23.834)
                            Um she was one of the girlfriends from uh Perfect Strangers is the woman that yes, yeah that&#39;s Yes, I cannot remember the woman&#39;s name I actually thought that this was that actress it&#39;s not So, uh, yeah, so he so evil is getting impatient because he needs to reach his destination by 10 in order to kill these girls</p>
                            <p>Dave! (01:10:28.074)
                            Yes. She was she was Balki&#39;s girlfriend on Perfect Strangers.</p>
                            <p>Dave! (01:10:45.674)
                            She says, Hey, well, at least Lisa got rid of her nervous diarrhea.</p>
                            <p>Bryan! (01:10:49.487)
                            with transcendental meditation. Which is like the perfect time capsule moment for 1980. Yep, it cleared her of her nail biting and her friend&#39;s nervous diarrhea, which is-</p>
                            <p>Dave! (01:10:54.971)
                            Mm-hmm.</p>
                            <p>Dave! (01:10:59.438)
                            This whole movie has a very like distinctly made for TV feeling to it. Like it is very much of the time.</p>
                            <p>Bryan! (01:11:03.846)
                            it does. I have that if there&#39;s a little bit of nudity, there&#39;s a little bit of blood. If it wasn&#39;t for those things, it would definitely feel like a like a Monday night movie kind of thing. So they make a quick pit stop now and thus begins the best scene in the entire movie. Maybe one of my favorite kill scenes in a Slasher movie ever. So</p>
                            <p>Dave! (01:11:25.498)
                            Because it is a thing that would never, absolutely never happen.</p>
                            <p>Bryan! (01:11:30.59)
                            No, so Evil tells the yappy girl&#39;s friend to go into a store, buy some champagne, and while she&#39;s gone, he proposes that he and she smoke some weed, and produces a gigantic bag of weed, inviting her to smell it, and now when she leans in, he hits record on his radio and slips the bag over her head, suffocating her with the bag of weed, and the weed is like jostling around in like its inner face and shit. It&#39;s like, it&#39;s like-</p>
                            <p>Dave! (01:11:40.507)
                            Mm-hmm.</p>
                            <p>Yeah.</p>
                            <p>Dave! (01:11:58.661)
                            It is incredibly awkward.</p>
                            <p>Bryan! (01:12:00.13)
                            It is like a slasher movie that Cheech and Chong made.</p>
                            <p>Dave! (01:12:03.182)
                            It&#39;s and also the whole premise of this, like killing it precisely mid that like it&#39;s so very specific, like he gets real antsy in the car. And that&#39;s why he&#39;s like, all right, I got to improvise. I&#39;ll send the one lady into the thing and then I&#39;ll kill her with the bag of weed, I guess. But it&#39;s like it is a very short window.</p>
                            <p>Bryan! (01:12:23.587)
                            Yeah. So, I&#39;ve also What&#39;s the deal with the</p>
                            <p>Dave! (01:12:29.375)
                            I guess so, in case people at the bar maybe saw them leave together.</p>
                            <p>Bryan! (01:12:33.182)
                            He put, because he turns out to be like a master of disguise, but like the disguises just don&#39;t play a role in anything. There&#39;s no reason.</p>
                            <p>Dave! (01:12:38.838)
                            Yeah, he does.</p>
                            <p>Dave! (01:12:43.882)
                            No, nothing in this movie plays a role. There is no continuity in this. There is no cohesion in the story.</p>
                            <p>Bryan! (01:12:47.81)
                            You know what? You know what? This is definitely a, you know, hey, shut up and watch the movie.</p>
                            <p>Dave! (01:12:53.354)
                            Yeah, this is like just look, we had three weeks and $600,000. The guy who wrote this is 65 years old. We don&#39;t have time.</p>
                            <p>Bryan! (01:12:57.238)
                            If he doesn&#39;t leave now, he&#39;s not going to get the <em>**</em> discount discount at Denny&#39;s. It&#39;s just a whole thing. We gotta get finished here. So, what are</p>
                            <p>Dave! (01:13:08.526)
                            Oh, because it only gets more absurd from here.</p>
                            <p>Bryan! (01:13:12.282)
                            When her friend emerges from the store, she finds Evil&#39;s car gone. But behind the store she finds Yappy Girl&#39;s shoe. This is turning into a Tarantino movie. And the second time a body is discovered by first finding a shoe. When she looks into a nearby dumpster, she finds Evil, mustache and all, waiting there to ambush her.</p>
                            <p>Dave! (01:13:33.418)
                            And it is such a weird because he is he is if you watch the trailer, it&#39;s in the trailer because it is sort of a weirdly unnerving moment. He&#39;s lighting up his face with a lighter and it&#39;s all you can see is sort of like his face, a glow and nothing else. And he has this weird grin on his face. It&#39;s just so fucking strange.</p>
                            <p>Bryan! (01:13:50.527)
                            Yeah.</p>
                            <p>Bryan! (01:13:54.346)
                            it does not look like the guy that we&#39;ve been seeing this whole moment. Yeah, I&#39;ll give you that. So, um yeah, and so and then he calls the cops and tells them where he can find the body. So, the cops find blood at the scene and they also find a jump scare cat and a little bit more searching turns up yappy girl in a playground dangling from the swing set with a bag of weed still over her head.</p>
                            <p>Dave! (01:13:56.306)
                            No. Looks like Kenneth Branagh.</p>
                            <p>Dave! (01:14:03.006)
                            And then he kills her and puts her in the dumpster.</p>
                            <p>Dave! (01:14:20.242)
                            Yeah. No dignity for her.</p>
                            <p>Bryan! (01:14:24.114)
                            No, and her friend is dead on a nearby slide. And the way that she slides into frame is fucking great. So Evil has now changed his costume. He&#39;s dressed like a priest next, for some reason, why not? He runs into some bikers who flip him off. And now a little ways up the road, he rear ends the bikers while not paying attention because he keeps looking at a picture of a nun in the passenger seat.</p>
                            <p>Dave! (01:14:49.65)
                            Yeah, this is a real Pee-Wee&#39;s Big Adventure moment.</p>
                            <p>Bryan! (01:14:52.518)
                            it is it a detail that I just didn&#39;t notice like it seems like it&#39;s pointing to something but it never yeah it never pays off.</p>
                            <p>Dave! (01:14:59.746)
                            The nun? I think they&#39;re just trying to give it a distraction. But like, why does it have to be a nun? Why can&#39;t it be the fact that you&#39;re surrounded by angry bikers?</p>
                            <p>Bryan! (01:15:05.29)
                            guess.</p>
                            <p>I know.</p>
                            <p>Bryan! (01:15:12.914)
                            Yeah. So, yeah. So, he flees and they give chase. He tries to lose them at a drive-in movie. So, the movie trailer running at the drive-in says that it&#39;s a movie called Blood Feast. It is not. It is not. It is actually the Jalo, the Red Queen kills seven times which was oh my god, mama mia. That&#39;s what I call it. That&#39;s that&#39;s what I call a convoluted Jalo right there, but it&#39;s great. Yep.</p>
                            <p>Dave! (01:15:25.335)
                            But it is not.</p>
                            <p>It is... yeah. That would be fucking rules.</p>
                            <p>Yeah.</p>
                            <p>Dave! (01:15:37.543)
                            Also one of my favorite soundtracks.</p>
                            <p>Bryan! (01:15:41.599)
                            Uh it was uh here&#39;s the thing. states as Blood Feast. Yes, it double feature with another</p>
                            <p>Dave! (01:15:46.519)
                            Really?</p>
                            <p>Dave! (01:15:52.134)
                            I was going to say that title doesn&#39;t make any sense, but you know what? Neither does the Red Queen kill seven times. Neither does any title that has ever been applied to a Jello.</p>
                            <p>Bryan! (01:15:57.906)
                            Nope, nope, cause I-</p>
                            <p>Bryan! (01:16:03.064)
                            No. So, uh yeah, at the drive in, we get a little cheap skin as the biker search for evil. Some dude is feeling up a girl in his car while she smokes some weed.</p>
                            <p>Dave! (01:16:11.146)
                            So here&#39;s where you get a real, like a distinctly 80s vibe in this movie. The tone of this thing is all over the place throughout the whole thing, but you get this, like this could have gone either way. It could have gone boring slasher, which it kind of is, or goofy comedy, because it&#39;s like, he is now, the hunter has become the prey, if you will, where he&#39;s like desperately trying to get something done.</p>
                            <p>Bryan! (01:16:34.231)
                            Hahaha</p>
                            <p>Dave! (01:16:38.37)
                            by a certain time, he has to kill these people by a certain deadline. And just wacky shit just keeps keep like, it just keeps falling in his way. All these hurdles. And it&#39;s just like, this could have very easily been a comedy. And you know what? It probably would have been a pretty decent comedy.</p>
                            <p>Bryan! (01:16:46.782)
                            Oh yeah, it&#39;s...</p>
                            <p>Bryan! (01:16:50.846)
                            Yup. It&#39;s, uh, it&#39;s wacky hijinks when our serial killer bumps into some bikers.</p>
                            <p>Dave! (01:16:58.498)
                            But it also, I think, is indicative of what is happening in slashers in general, because that early phase, they&#39;re real brutal movies. Like, even if you go sort of down, you know, you got your top tier ones, the kind of lower you go, the sleazier and grimeier and grind house-ier they get. But as you get into the, they start to inject humor, the more, I think,</p>
                            <p>Bryan! (01:17:05.462)
                            Mm-hmm.</p>
                            <p>Dave! (01:17:26.134)
                            production companies and studios start to realize like, oh, these are like kids and teenagers who are going to these movies and they&#39;re probably all stoned or hopped upon goofballs or whatever it is they&#39;re doing. And so they like start to inject weird humor into shit. And you can kind of see that in this. Like you can see that they&#39;re really pitching to a young audience. Now, I would say if you wanna pitch a movie to a young audience, maybe get someone a little bit younger to write it.</p>
                            <p>Bryan! (01:17:36.203)
                            Mm.</p>
                            <p>Bryan! (01:17:53.462)
                            I know. Yeah. But also, just like we could. I mean, just. Yeah, no. But also, just like we covered in our blood rage episode, it seems like drive ins were places where people just went to fucking public. Yeah, it&#39;s just these are this is the impression.</p>
                            <p>Dave! (01:17:55.658)
                            so it doesn&#39;t feel like my mom wrote a horror movie.</p>
                            <p>and then someone else&#39;s mom starred in it.</p>
                            <p>Dave! (01:18:10.658)
                            That was just mayhem. Like you&#39;re either there to have sex or to murder a priest.</p>
                            <p>Bryan! (01:18:16.959)
                            So when the bikers get close, Evil abandons his car and leaves on foot, but on his way out, one of the bikers confronts him. Evil tells him, I&#39;m a man of God, not a man of violence.</p>
                            <p>Dave! (01:18:27.21)
                            Now look, I must say, for a compulsive serial killer, he sure does have a lot of whimsical costumes.</p>
                            <p>Bryan! (01:18:32.878)
                            Yeah, he&#39;s got costumes. He&#39;s got what really, really he thinks on his feet. He&#39;s got one liners.</p>
                            <p>Dave! (01:18:37.678)
                            Because he is giving Father Guido Sartucci at this point.</p>
                            <p>Bryan! (01:18:40.594)
                            Yeah, but also for a guy who&#39;s not a man of violence, he then stabs him to death. Yeah, so back to the couple in the car. Evil pulls the dude out of the backseat, demands to know where the keys are, and the guy talks like Bob Goldthwait in the 80s. It&#39;s like, hey, they&#39;re like in the front seat!</p>
                            <p>So evil then takes off with the topless girl in the backseat. I&#39;m I fucking suck it. I can&#39;t do it. You do it. Okay.</p>
                            <p>Dave! (01:19:06.026)
                            That&#39;s a real terrible Bob complete.</p>
                            <p>Dave! (01:19:12.086)
                            I can&#39;t do that one. Hey, remember that kid from You Can&#39;t Do That on television? And he was like the voice, the man of a thousand and one voices, but the only voice he ever did was Bobcat Goldthwaite.</p>
                            <p>Bryan! (01:19:18.286)
                            It&#39;s always the same voice. Comedy gold mine. That is a fucking funny joke. So, uh, five, we&#39;re now five minutes from midnight in the mountain time zone. Evil gets rid.</p>
                            <p>Dave! (01:19:26.668)
                            Oh man, the 80s. A real hot time.</p>
                            <p>Dave! (01:19:41.422)
                            He&#39;s, you know, for someone who&#39;s on a fucking tight schedule, he&#39;s real blase and just kinda hangin&#39; out.</p>
                            <p>Bryan! (01:19:45.118)
                            the next one. Evil gets ready to kill his next one when a couple of drunks wander into the street in front of his car and the girl seizes the opportunity to run and evil chases her with his radio slung over his shoulder and then he catches up to her at some sort of sports park and she&#39;s hidden under an outbuilding of some sort. But some cops catch up to him forcing him to flee missing his opportunity to kill now that scene with the girl in the back seat is</p>
                            <p>out of step with the rest of the movie. This is kind of a light, silly movie, and it&#39;s extremely sleazy and menacing and wicked nasty. And she says the thing. Oh yeah, it&#39;s a very, very upsetting scene. Now, back at the show.</p>
                            <p>Dave! (01:20:16.93)
                            because it feels very sleazy.</p>
                            <p>Dave! (01:20:24.63)
                            Like it feels more like it belongs in like maniac than it does this.</p>
                            <p>Dave! (01:20:33.802)
                            And I&#39;ll tell you, though, one of the things that because I lately I think it&#39;s like the older I get, the more the kind of mingling of sex and violence upsets me. And I mean, there&#39;s unfortunately a very big component of these movies. But like, I feel like lately I&#39;ve just been watching some and watching a lot of greenhouse movies lately. And it&#39;s just like they&#39;re so I don&#39;t want to see that. I just I don&#39;t want to see that kind of like sadism. And it&#39;s it feels really out of like out of out of</p>
                            <p>Bryan! (01:20:43.271)
                            Yeah.</p>
                            <p>Dave! (01:21:04.3)
                            a whack at this movie because it&#39;s like that&#39;s not the tone of this movie at all.</p>
                            <p>Bryan! (01:21:07.814)
                            No, this movie is really quite silly. So back at the show, the cop tries to address the crowd who hate him. It&#39;s how punk.</p>
                            <p>Dave! (01:21:15.538)
                            And someone says, ah, shut up enough already. It&#39;s like, dude, he said one thing. All he said was attention.</p>
                            <p>Bryan! (01:21:19.923)
                            Yeah, it&#39;s how punk. It&#39;s how punks are you see they just hate law and order.</p>
                            <p>Dave! (01:21:25.593)
                            Yeah. We just want to sniff more glue and lazily roll around on the floor.</p>
                            <p>Bryan! (01:21:28.502)
                            I&#39;m out.</p>
                            <p>Bryan! (01:21:31.85)
                            slam damps like a bunch of savages. They bring in a psychologist to add a little profile about the killer. They see he&#39;s compelled to</p>
                            <p>Dave! (01:21:39.314)
                            This guy&#39;s monologue feels like beat for beat exactly what he did in his audition.</p>
                            <p>Bryan! (01:21:44.254)
                            Oh my god. So he is compelled to kill on the hour, you see, and there&#39;s some flimsy reason he&#39;s gonna kill Blaze. The psychologist is fucking nuts. He talks like Joe Friday through his teeth. And he&#39;s doing a... Yeah, and he&#39;s doing a lot of eyebrow acting.</p>
                            <p>Dave! (01:21:55.158)
                            which again is very 80s.</p>
                            <p>Dave! (01:22:00.406)
                            But they keep they keep kind of positioning Blaze as though she&#39;s the ultimate victim and all this. It&#39;s like, bitch, he has killed seven people. You&#39;re not the victim.</p>
                            <p>Bryan! (01:22:09.578)
                            just total randos and they keep going like, yeah, this is the second time where she&#39;s like, you&#39;re where anybody is like, oh, they&#39;re coming for her. It&#39;s like, what makes you think that?</p>
                            <p>Dave! (01:22:18.734)
                            Yeah, and she has experienced the least of the trauma. This is the complaint of like a lot of people with the Halloween franchise where like Jamie Lee Curtis is just progressive. Like he&#39;s coming after me. And it&#39;s like, well, why do you think that he&#39;s coming after you? He was just killing random people the first time around. You just happen to be in the way. Someone&#39;s a little self-important, I think. Blaze.</p>
                            <p>Bryan! (01:22:35.322)
                            Yeah. So, yeah, as they&#39;re explaining this, Derek is lurking in the background, still wearing the stocking over his head, wearing some of those punk rock sunglasses.</p>
                            <p>Dave! (01:22:46.606)
                            Oh, the psychologist also says he&#39;s mutilated the breasts of his victims. That&#39;s a common fixation of a psychopathic killer who has a mother fixation. That&#39;s some super 80s shit right there. I&#39;m not going to say they were entirely wrong because they&#39;re not, but.</p>
                            <p>Bryan! (01:22:57.891)
                            Yeah, yeah, it&#39;s...</p>
                            <p>Bryan! (01:23:01.882)
                            it&#39;s all Freudian. So, evil now arrives at the luxurious Holiday Inn but he yeah, he arrives in time to see the cops hassling a guy. I know. I know. You want to know who else is doing their thing? Yakov Smirnov. Yeah and he&#39;s still booking him like crazy out in Branson, Missouri.</p>
                            <p>Dave! (01:23:09.486)
                            Still dressed like Guido Sarducci.</p>
                            <p>Who, by the way, still does the Guido Sarducci bit? And I say, thank God for that.</p>
                            <p>Dave! (01:23:22.751)
                            Man, that&#39;s great.</p>
                            <p>Dave! (01:23:30.004)
                            They said that they love the arts. Also, totally dry town, by the way. If you want me to go see Jakob Smirnov, I am not doing it sober.</p>
                            <p>Bryan! (01:23:32.358)
                            I know. I know. I I&#39;ve got a</p>
                            <p>Bryan! (01:23:41.024)
                            No, I&#39;ve got a friend who has been to Branson multiple times. Because it&#39;s a long story.</p>
                            <p>Dave! (01:23:44.822)
                            Why? I mean, no offense to anyone in Missouri. I don&#39;t think we have any listeners in Missouri, but if we do, no offense.</p>
                            <p>Bryan! (01:23:51.634)
                            No, I&#39;d go to, I&#39;d go to Bronson, Missouri.</p>
                            <p>No.</p>
                            <p>Dave! (01:23:55.943)
                            I feel like if data is any indication, most of our listeners are in New York and California, you know, where the cool kids are.</p>
                            <p>Bryan! (01:24:03.178)
                            that&#39;s right. That&#39;s right. So, he&#39;s they&#39;re hassling a guy who arrived to the show late and they&#39;re no longer admitting anybody. Dude just hanging out there in chaps. At least he wore jeans under him. So, he sneaks around back, finds the back finds the back door guarded by two cops and when one of them walks away, he lures the other cop over to where he is and he smacks him</p>
                            <p>Dave! (01:24:19.286)
                            Very punk rock of him.</p>
                            <p>Dave! (01:24:27.862)
                            Yeah, he says, I think I found a drunk. What does that even mean? Why didn&#39;t you just yell, hey, help? Like, why is that the thing he says? I feel like now I&#39;m just splitting hairs. Just watch the goddamn movie.</p>
                            <p>Bryan! (01:24:30.606)
                            Mm-mm. Yeah. Come on.</p>
                            <p>Bryan! (01:24:43.756)
                            the song. Yeah. So, inside, we Japan. It&#39;s not very good.</p>
                            <p>Dave! (01:24:46.57)
                            Nope. And I believe Blaze does a Japanese impression.</p>
                            <p>Bryan! (01:24:53.01)
                            Uh yeah, I can&#39;t remember what she says, but fuck. Yeah, so outside evil master of disguise as the cops uniform on uses it to get into the hotel. Now once inside he locates a trunk of costuming that he left there just for this occasion and gets I know he really planned his time out. He put all the shit in the place where he needed it to be. So yeah.</p>
                            <p>Dave! (01:24:55.366)
                            It is offensive.</p>
                            <p>Dave! (01:25:10.182)
                            I told you this is a guy he&#39;s got a real tight schedule, but he&#39;s got time for costumes. And I appreciate that. That&#39;s my kind of killer.</p>
                            <p>Bryan! (01:25:22.546)
                            Now there&#39;s some more drama in bl-</p>
                            <p>Dave! (01:25:23.414)
                            Because we&#39;re getting to the why of all of this, and it is real disappointing.</p>
                            <p>Bryan! (01:25:26.026)
                            Mm hmm. Yeah, so there&#39;s some drama in Blaze&#39;s dressing room having to do with Derek. He runs off. Uh-huh. And then, and moments later, a man wearing a silly mask carrying a switchblade enters the room. Then there&#39;s a bit of menace. First, it looks like he&#39;s going to stab her. Then he motions like he&#39;s going to strangle her. But then she turns around frightened and he takes the mask off. It&#39;s evil. And he&#39;s smiling. This was like this was all some kind of goof. It turns out.</p>
                            <p>Dave! (01:25:32.694)
                            Cause all of a sudden she cares about her son.</p>
                            <p>Bryan! (01:25:54.39)
                            evil is really Richard, her husband. Not. Yeah. So. Uh, they thought they were being clever.</p>
                            <p>Dave! (01:25:56.834)
                            Get the fuck out of here with that. Why bother at all?</p>
                            <p>Dave! (01:26:05.806)
                            So I have a theory about this, though. I think that they had this mask probably, and it turned out this mask was probably very similar to other masks in other movies. Because it looks a little bit like I mean, it looks exactly like the thing from Deep Red, but it looks a little bit like the mask, one of the masks in Terror Train.</p>
                            <p>Bryan! (01:26:16.103)
                            Hmm.</p>
                            <p>Bryan! (01:26:26.214)
                            That&#39;s yes it does actually the that&#39;s the scene in the dumpster when he does the lighter thing. I always think he looks like the fucking mask the killer wears a terror train. Yeah, but</p>
                            <p>Dave! (01:26:32.162)
                            Mm-hmm.</p>
                            <p>Dave! (01:26:38.122)
                            Yeah. And I wonder if like they had planned for him to wear a mask all along. They were like, fuck it. You know, we don&#39;t have time to come up with anything else. Let&#39;s just do it. Because the reveal that like, you know, it&#39;s her husband. It&#39;s very like, wait, she has a husband? Because we never see them together. They only mentioned him one time.</p>
                            <p>Bryan! (01:26:52.978)
                            Yeah, that one that one. Now we hear about him. Yeah, well, they mentioned him and she seems like, oh, he&#39;s probably coked up and drunk. So she doesn&#39;t like him much either. But yeah, also the whole time he.</p>
                            <p>Dave! (01:27:06.69)
                            Well, based on his behavior this evening, I don&#39;t think I like him either.</p>
                            <p>Bryan! (01:27:10.158)
                            No, no, no. He reminds me of Jeffrey Combs a bit, and he looks like him, and he definitely talks like him.</p>
                            <p>Dave! (01:27:14.614)
                            Yes.</p>
                            <p>Yes, because Jeffrey Combs plays the same character in every movie. And I however great they are, it&#39;s the same fucking character in every movie. Yeah, no, just keep being you because it works great every time. But I just I was like, it&#39;s such a it&#39;s such a wet fart of a reveal. Like it&#39;s like what? This is this is not it. It can&#39;t be a surprise. It can&#39;t be the big twist at the end.</p>
                            <p>Bryan! (01:27:20.445)
                            Yeah.</p>
                            <p>Bryan! (01:27:24.944)
                            you&#39;re great. Yeah, don&#39;t,</p>
                            <p>Bryan! (01:27:39.243)
                            Yeah.</p>
                            <p>Dave! (01:27:45.618)
                            if I don&#39;t know who this guy is to begin with. It&#39;s a big reveal if I&#39;d seen him before and then he takes the mask off and it&#39;s her husband.</p>
                            <p>Bryan! (01:27:54.646)
                            I disagree. I think that you can you can you can definitely do that. You can you can set up this mystery and then in the in the <em>**</em> third act, you</p>
                            <p>Dave! (01:28:03.03)
                            But you can&#39;t treat it like it&#39;s a reveal. You can let it be the mystery, that&#39;s fine. But just have him walk into the fucking room. But this big take the mask off reveal, it doesn&#39;t fucking matter because we didn&#39;t know it was her husband. So just let him walk into the room, it&#39;s the same thing.</p>
                            <p>Bryan! (01:28:14.686)
                            Yeah, but anyway, I know the mask part is a bit more is a bit much, but I feel like that is a perfectly valid thing is a detail of the movie that I&#39;ve never really seen elsewhere where they&#39;re just like, who is this killer and then they reveal. So yeah, then they use the scene to remind us all what has happened in case you forgot it because this movie is not even 90 minutes long and you apparently need a little bit of a reminder.</p>
                            <p>Dave! (01:28:30.75)
                            It seems like a happy accident to me.</p>
                            <p>Bryan! (01:28:43.89)
                            She explains to Evil what he&#39;s done while he plays stupid, and then he leaves to carry out the rest of his evil plan. And now the cop gets a call that fills him in on what happened at the drive-in. So now everybody is looking for Evil Richard, but Evil Richard has done some electrical magic stuff to the elevator. So when Blaze...</p>
                            <p>Dave! (01:29:01.11)
                            Because the elevator, the mechanics of the elevator, it&#39;s just down in the lobby for everybody.</p>
                            <p>Bryan! (01:29:06.342)
                            Yeah. So when blaze and one of the cops get in, he does something to it to make it fall out of control. And then he eventually stops it at whatever floor he&#39;s at, which sort of knocks out the cop and then he sits down next to blaze and It plays.</p>
                            <p>Dave! (01:29:18.618)
                            This whole part is very confusing. And then we get to the end where he explains his motive and it&#39;s like, oh, fucking barf.</p>
                            <p>Bryan! (01:29:26.283)
                            He plays here the recording of him killing Taffy the nurse and then he monologues. And his whole thing is that he hates women, surprise, and the only victims in this movie are women.</p>
                            <p>Dave! (01:29:27.278)
                            Jesus</p>
                            <p>Dave! (01:29:35.362)
                            You know what&#39;s funny about this though is, so slashers throughout the 80s, they are constantly being accused of being misogynistic and justifiably because most of them are very misogynistic. This one actually isn&#39;t. Until you get to the end and then it&#39;s like, wait, that&#39;s your reason? That is the laziest shit I&#39;ve ever heard. It&#39;s like, well, I don&#39;t know, he hates women, I guess.</p>
                            <p>Bryan! (01:29:53.67)
                            Yeah. It feels here&#39;s the thing. It feels very on brand for Canon. Like from what and the thing is I&#39;m giving Canon a little too much credit. Those guys really just paid for the movie and they slap their logo on it. This is filmmakers, but there is a there is just a</p>
                            <p>Dave! (01:30:12.234)
                            I don&#39;t know. I think they were pretty heavy. Like there were a lot of like phone calls like, no, you&#39;re not going to do that.</p>
                            <p>Bryan! (01:30:17.458)
                            Yeah. So yeah.</p>
                            <p>Dave! (01:30:19.65)
                            But it&#39;s very much like you&#39;re turning our son into a, like we never see any, it&#39;s the same stupid bullshit. Like you&#39;re an overbearing mother. You&#39;re a, you know, domineering wife, blah, blah. It&#39;s that dumb shit, but we never see any of this. So it&#39;s like, I don&#39;t, you know, there&#39;s a, there&#39;s a different, when you watch movies, there&#39;s a difference between being told that this is the next explanation and being told to think so. So this movie is not didactic in any way. Like,</p>
                            <p>Bryan! (01:30:37.33)
                            Well, no, you know what it is?</p>
                            <p>Bryan! (01:30:47.506)
                            No, they do. They do set this up at the very beginning. It&#39;s just done very, very haphazardly and not terribly effectively so that when this reveal comes around, you go, okay, I guess so.</p>
                            <p>Dave! (01:31:00.01)
                            Well, maybe that&#39;s maybe that speaks to the era in which it&#39;s made more than anything else, because I read that as this is a sort of a I don&#39;t know, an uninterested, perhaps even disinterested mother and maybe like a kind of slightly disgruntled wife. But they don&#39;t really elaborate on any of these things. She just seems like a woman who&#39;s kind of like singularly focused in this moment and doesn&#39;t want to be bothered with any family bullshit.</p>
                            <p>Bryan! (01:31:26.196)
                            Yeah, yeah, they don&#39;t.</p>
                            <p>Dave! (01:31:26.526)
                            I guess if you are a man in 1980, you could read that as like, oh, she doesn&#39;t care about her family and she&#39;s, you know, whatever, but like it doesn&#39;t read that way. So to get this dumped on you at the end, it&#39;s kind of like, I guess, but not really.</p>
                            <p>Bryan! (01:31:33.948)
                            Yeah.</p>
                            <p>Bryan! (01:31:38.906)
                            Yeah. That I mean, that was that was my reaction exactly was, oh, okay. So.</p>
                            <p>Dave! (01:31:46.038)
                            I mean, he could have said anything, it wouldn&#39;t fucking matter.</p>
                            <p>Bryan! (01:31:48.734)
                            No, no evil now chains her up to the bottom.</p>
                            <p>Dave! (01:31:51.378)
                            Oh, because he&#39;s also, he was supposed to be in the, he had spent time in that psychiatric hospital.</p>
                            <p>Bryan! (01:31:57.694)
                            Yes, and yet nurse Taffy did not recognize him. So.</p>
                            <p>Dave! (01:32:02.01)
                            Well, because it&#39;s actually not a psychiatric hospital, because it&#39;s the same fucking hotel that they&#39;ve been filming everything else in. It is very clearly not a hospital, and it&#39;s a hotel they&#39;re in.</p>
                            <p>Bryan! (01:32:07.53)
                            Hahaha, yeah.</p>
                            <p>No, like, yeah, like hours before they had a breakfast, like a brunch buffet set up there. So, dude, I could go for some fucking pancakes right now. So the plan is to do some more magical electrical stuff to the elevator, because she&#39;s chained to the bottom of it and take her.</p>
                            <p>Dave! (01:32:17.169)
                            I wish I had brunch right now.</p>
                            <p>Dave! (01:32:28.594)
                            Again, I don&#39;t like that you just access the is this is this the way it is everywhere? I&#39;m not taking an elevator anywhere. I say that like I go a bunch of places.</p>
                            <p>Bryan! (01:32:34.938)
                            Hahaha</p>
                            <p>completely turned around on elevators now. They yeah, so the thing is she&#39;s in fall, die on impact. She&#39;s wearing a ridiculous sparkly red jumpsuit now.</p>
                            <p>Dave! (01:32:48.573)
                            Everything she has worn in this movie is absolutely ridiculous.</p>
                            <p>Bryan! (01:32:51.838)
                            Yeah, so at the top floor, Blaze sees Yvonne&#39;s body in the elevator shaft for some fucking reason. Beads in her hair still clicking away.</p>
                            <p>Dave! (01:33:00.482)
                            Because you gotta wonder, has he been carting her body around? Was she in the fucking trunk with all the costumes?</p>
                            <p>Bryan! (01:33:07.89)
                            I know. So, maybe. Maybe, because that&#39;s a, it&#39;s a big trunk. So, yeah. Now, with Blaze falling quickly and some drunk punks stuck in the elevator, the police finally show up. Now, Evil Richard, I love them, I love them to death, they&#39;re very fucking funny. Evil Richard shoots at the cops, and then the police shoot back, and then all the shooting jacks up the electrical elevator box, causing the elevator to stop falling.</p>
                            <p>Dave! (01:33:09.842)
                            I bet that&#39;s why the beads are there, too. Very audible.</p>
                            <p>Dave! (01:33:24.114)
                            I kind of love the couple in the elevator though.</p>
                            <p>Bryan! (01:33:36.698)
                            And there&#39;s a couple of shots where there are like real bullet hits hitting the wall like right next to</p>
                            <p>Dave! (01:33:43.182)
                            Oh yeah, because she was also, she was, I think, actually chained to the mechanics of the elevator. Like I watched a thing and they were like, if anything had fucked up, she could have been torn apart.</p>
                            <p>Bryan! (01:33:48.584)
                            You know.</p>
                            <p>that you know what her back is to you when they&#39;re doing that that had to be a stunt woman. Yeah. So, you know what? I you know what I love stunt people for the the things that they do. I would I love seeing people do crazy <em>**</em> stunts like yeah with sorcerer. So yeah now uh the cops chase evil Richard to the roof where he puts the silly</p>
                            <p>Dave! (01:33:56.29)
                            I hope so. I mean, I hope not. I hope it was a dummy. I don&#39;t think he could be chaining anyone to the elevator. That seems unwise.</p>
                            <p>Dave! (01:34:10.402)
                            You know what you should see? Stunt Rock. Speaking of sorcery.</p>
                            <p>Dave! (01:34:21.022)
                            And then quotes Shakespeare for no particular reason at all.</p>
                            <p>Bryan! (01:34:22.712)
                            right? Is it? I think the mask is supposed to be Richard Nixon.</p>
                            <p>Dave! (01:34:28.334)
                            Okay. Still, the connection&#39;s a jump.</p>
                            <p>Bryan! (01:34:29.37)
                            Yeah, but yeah, court.</p>
                            <p>Yeah, so cornered, Evil Richard drops his weapons and then does the to die to sleep no more soliloquy from Hamlet before leaping from the roof. Now, another canon slasher movie, X-Ray, which we talked about earlier, which we&#39;re doing in about a month, produced by the same guy as this one, released the following year has an almost identical ending.</p>
                            <p>Dave! (01:34:46.376)
                            Oh, so good.</p>
                            <p>Dave! (01:34:52.186)
                            Mm hmm. Schizo came out the same. The schizo is another movie that was produced by the same guy. Also a canon movie came out the same year. That movie is not good.</p>
                            <p>Bryan! (01:34:59.922)
                            Yep. And if you get it on vinegar syndrome, if you get it, if you get the vinegar syndrome release of x-ray, you also get schizo.</p>
                            <p>Dave! (01:35:07.262)
                            And you should. You should buy things from vinegar syndrome. That&#39;s not even a paid advertisement. I just like them.</p>
                            <p>Bryan! (01:35:11.678)
                            Nope, I love him. Vinegar syndrome. Hit us up. Derek finds his dad dead on the ground and takes the mask off with a crazy look on his face. And the cops meanwhile, load Blaze into an ambulance. The driver is revealed to be Derek wearing the mask with the actual driver dead on the floor next to him. We hear a radio broadcast wishing happy new year from Hawaii, implying that he killed Blaze.</p>
                            <p>Dave! (01:35:35.222)
                            And you think, are we gonna get a sequel? No, because this movie was terrible.</p>
                            <p>Bryan! (01:35:38.462)
                            No. Yep. So now New Year&#39;s Evil song plays, roll credits. New Year&#39;s Evil.</p>
                            <p>Dave! (01:35:46.186)
                            Oh man. I&#39;m going to ask the normal question, I guess, but what do you think of this movie today?</p>
                            <p>Bryan! (01:35:54.354)
                            Yeah. So, here&#39;s the thing. I had never seen this one. At a certain point when we were when we would get up to like renting movies, I got really kind of tired of the holiday slasher kind of also rams.</p>
                            <p>Dave! (01:36:08.17)
                            I will say the cover for this, is one of the ones that is sort of like just imprinted in my brain from the video store.</p>
                            <p>Bryan! (01:36:14.086)
                            Oh yeah, it&#39;s like that one and Happy Birthday to me always really stick out to me. Like they&#39;re very compelling, but like something about it, I was just like, I don&#39;t want to see another fucking holiday slasher. Like there&#39;s a few that are really quite... yeah. And most of them are just not very good. And this one didn&#39;t really draw me in. So until we did this episode, I&#39;d never seen it. My final thoughts on it are...</p>
                            <p>Dave! (01:36:27.362)
                            Yeah, because they all came out in the same fucking year. Roger Corman felt the same way.</p>
                            <p>Bryan! (01:36:43.834)
                            It is not good. It is not a good movie, but, but it has its charms. It is very silly. It&#39;s very funny. It has a really weird take on punk rock and punk rock culture. I had a really good time with it. I would watch it again for sure. Like, I don&#39;t think that this is not, this is not like, uh, like Christmas evil or silent night, deadly night where it&#39;s like a movie. I&#39;m definitely going to be watching for like on a sort of holiday traditional schedule.</p>
                            <p>Dave! (01:36:46.151)
                            Mm-mm.</p>
                            <p>Bryan! (01:37:13.502)
                            But I don&#39;t know, maybe in the future when there&#39;s nothing else to watch, I&#39;ll put it on.</p>
                            <p>Dave! (01:37:18.351)
                            Yeah, I probably saw it maybe 15 years ago, 20 years ago, maybe. And I haven&#39;t seen it that many times. I&#39;ve maybe seen a couple two or three times. I in some ways, I what I like about it is you can tell. And especially if you watch the special features on the Blu Ray, you can tell that they&#39;re having fun. And I think that goes a long way, especially when you have a low budget movie. The energy, if it&#39;s right.</p>
                            <p>It can convey something fun. I think there is a lot of misogyny in this. That&#39;s a little bit. It&#39;s a little insidious, maybe. It is a fucking mess narratively. Like they introduced this kind of motive and strategy. This kind of his operating procedure at the beginning. But they don&#39;t really stick to it. Nobody commits to anything in this movie. It&#39;s totally really weird.</p>
                            <p>Bryan! (01:38:09.471)
                            No.</p>
                            <p>Bryan! (01:38:13.398)
                            the whole like killing on the hour thing is there to sort of give the movie some stakes to kind of give it a little bit of momentum but they never commit to it fully and so it doesn&#39;t really go anywhere. If this movie is interesting in any way, it&#39;s just that canon. Canon movies in particular the Golden Globus era.</p>
                            <p>have a weird quality to them that feels completely removed from any reality that you&#39;re familiar with like it&#39;s every one of their movies has a very bizarre internal logic to it and when it&#39;s applied to the slasher movie formula which is a really simple formula like it does it it&#39;s yeah it&#39;s just</p>
                            <p>Dave! (01:38:53.218)
                            Oh, it&#39;s very basic. It&#39;s, it&#39;s, and then there were none. That is the, the slasher formula is, and then there were none. That&#39;s it.</p>
                            <p>Bryan! (01:38:58.93)
                            It&#39;s just it&#39;s a body count movie but they over complicate it in ways that are really amusing. So, yeah, because I mean they really</p>
                            <p>Dave! (01:39:04.854)
                            but at the same time they don&#39;t commit to it. And it just gets so convoluted and weird and by the end you&#39;re like, wait, what?</p>
                            <p>Bryan! (01:39:12.774)
                            No, like this is them just trying to make up a little bit of money. Like, well, like they probably had a very cynical approach to it where they were like, fuck it, this is stupid. Anybody can make a fucking horror movie. And they.</p>
                            <p>Dave! (01:39:21.854)
                            I will say though, because people might be like, well, why? You guys have been focused really on Canon. Who is the distributor of the movie? Not the production, well, I guess they were in the production company, but it is an exercise in branding because it is brilliant branding. You know exactly. It&#39;s like it&#39;s either New World, which was a little bit higher class, Canon or Vestron, and you can tell them all just by watching them.</p>
                            <p>Bryan! (01:39:43.496)
                            Mm-hmm.</p>
                            <p>Uh huh. Yes, you certainly can.</p>
                            <p>Dave! (01:39:49.45)
                            Like that is a very impressive feat for a distributor. I would say this is not like, I mean, it&#39;s funny because we&#39;ve been doing this, I ordinarily would not have thought of movies in this way, which is kind of where the method pays off a little bit, is that I would never have thought about it like this, but we&#39;ve been watching holiday movies. I am so fucking sick of holiday horror movies. Like I am so glad we can all stop watching the same goddamn movies now.</p>
                            <p>Bryan! (01:39:53.182)
                            Yeah.</p>
                            <p>Bryan! (01:40:10.174)
                            Dude, I am so, I am so glad. I am so glad that we are past this because like, we did elves and then all of a sudden, every fucking other horror podcast in our, in our Instagram feed was doing elves. Everybody of course had to do Silent Night Deadly Night. Everybody was doing Christmas evil. Everybody was doing Black Christmas. And I am so fucking holiday horrid out. I&#39;m so glad.</p>
                            <p>Dave! (01:40:21.201)
                            Yep.</p>
                            <p>Dave! (01:40:31.658)
                            I also think that most of them suck.</p>
                            <p>Bryan! (01:40:34.326)
                            Ah, yeah. I don&#39;t know. I think I think I&#39;m just a little burnt out on the motif. I&#39;m so glad that we&#39;re moving on because what the fuck are we doing next week?</p>
                            <p>Dave! (01:40:43.554)
                            Oh, well, I&#39;ll tell you what, we were going to do yet another New Year&#39;s Eve horror movie. We&#39;re going to do a territory. And I thought maybe two New Year&#39;s Eve horror movies is one too many. You might argue it&#39;s too, too many. Instead, we&#39;re going to be doing Fright Night, which is a fucking awesome movie. And I believe we have a pretty awesome guest for it. I&#39;m not going to say who it is yet, because you never know. But.</p>
                            <p>Bryan! (01:40:56.214)
                            Yeah.</p>
                            <p>Bryan! (01:41:01.562)
                            Yes! We&#39;ve got a fuckin&#39; We&#39;ve got a fuckin&#39; great guest. I&#39;m so excited about this one.</p>
                            <p>Dave! (01:41:11.826)
                            So in two weeks we will be back with Fright Night, but before that, next week, next Monday on 99 Cent Rental, we watched Polyester, which is my favorite John Waters movie.</p>
                            <p>Bryan! (01:41:21.662)
                            Yeah. We had a really good time with that one. So be on the lookout. Oh and yeah and regarding the whole 99 cent rental thing, we&#39;re gonna keep putting the ninety, you know, we&#39;re gonna keep putting those episodes in this feed until probably the end of the month and then we&#39;ll spin that off over into its own feed just so you&#39;re not.</p>
                            <p>Dave! (01:41:37.518)
                            Spoiler, it&#39;s the same show. We just talk about all their dumb shit.</p>
                            <p>Bryan! (01:41:39.614)
                            It&#39;s the same show. Yeah, but yeah, no. So definitely subscribe to that one because it&#39;s a lot of fun. We&#39;ve got some great movies lined up for that one also, but yeah, so one week.</p>
                            <p>Dave! (01:41:49.038)
                            and some real highbrow stuff. Well, OK, I can think of at least one highbrow thing. And even that is a little questionable.</p>
                            <p>Bryan! (01:41:55.689)
                            That&#39;s a bit of a bit of a bit of a stretch. But yeah, so in one week, polyester on 99 cent rental and in two weeks, Friday night.</p>
                            <p>Dave! (01:42:03.37)
                            Yay-oh!</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/fright-night">Next Episode</Link></li>
                        <li><Link to="/episodes/black-christmas">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)